// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  menuItem: getIcon("ic_menu_item"),
  search: getIcon("ic_search"),
  teams: getIcon("ic_team"),
  squads: getIcon("ic_squads"),
  player: getIcon("ic_player"),
  org: getIcon("ic_org"),
  hub: getIcon("ic_hub"),
  teamsIManage: getIcon("teams_manage"),
};

// const navConfig = [
//   // GENERAL
//   // ----------------------------------------------------------------------
//   {
//     subheader: '',
//     items: [
//       { title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard },
//       { title: 'Search', path: PATH_DASHBOARD.search, icon: ICONS.search },
//     ],
//   },

//   ,
// ];

export function parseNavConfig(user) {
  let items = [
    { title: "Dashboard", path: PATH_DASHBOARD.app, icon: ICONS.dashboard },
  ];

  if (user.goals) {
    items.push({
      title: "Search",
      path: PATH_DASHBOARD.search,
      icon: ICONS.search,
    });
  }

  if (
    user.goals &&
    (user.goals?.isPlayerLookingForOtherPlayersToJoinNonProTeamOrToPlay ||
      user.goals?.isPlayerLookingForProTeam ||
      user.goals?.isPlayerAlreadyOnProTeam ||
      user.goals?.isPlayerLookingForCollegeTeam ||
      user.goals?.isPlayerAlreadyOnCollegeTeam ||
      user.goals?.isPlayerLookingForPlayersToPlayInTournament)
  ) {
    items.push({
      title: "My Player Profile",
      path: PATH_DASHBOARD.profile.player.root,
      icon: ICONS.player,
    });
  }

  if (user.goals?.isCollegeTeamRecruitingPlayers) {
    items.push({
      title: "My Organization Profile",
      path: PATH_DASHBOARD.profile.organization.root,
      icon: ICONS.org,
    });
  }

  if (
    user.goals?.isCollegeTeamRecruitingPlayers ||
    user.goals?.isProTeamRecruitingPlayers ||
    user.goals?.isNonProTeamRecruitingPlayers
  ) {
    items = [
      ...items,
      {
        title: "Players I Manage",
        path: PATH_DASHBOARD.players,
        icon: ICONS.teams,
      },
      {
        title: "Teams I Manage",
        path: PATH_DASHBOARD.teams.root,
        icon: ICONS.teamsIManage,
      },
      {
        title: "My Prospects",
        path: PATH_DASHBOARD.squads.root,
        icon: ICONS.squads,
      },
    ];
  }

  return [
    {
      subheader: "",
      items,
    },
  ];
}

// export default navConfig;
