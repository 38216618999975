import axiosInstance from "src/utils/axios";
import axios from "src/utils/axios";

export class TeamService {
  static getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("accessToken"),
      },
    };
  }

  static async getTeams(user) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/teams`,
        TeamService.getHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }

  static async getOrganizationTeams(user, orgId) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/organizations/${orgId}/featured`,
        TeamService.getHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }

  static async getPlayersIManage(user) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user}/players-manage`,
        TeamService.getHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }

  static async getTeamPublicProfile(id) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${id}`,
        TeamService.getHeaders()
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }
  static async getTeam(user, teamId) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/teams/${teamId}`,
        TeamService.getHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }

  static async getTeamWithRestrictions(teamId) {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}`,
        TeamService.getHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
    return null;
  }
  static async createProfile(userId, values) {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams`,
        { ...values },
        {
          headers: TeamService.getHeaders(),
        }
      );
      if (response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async updateProfile(userId, teamId, values) {
    try {
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams/${teamId}`,
        { ...values },
        {
          headers: TeamService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw new Error("Failed to update team");
    }
    return null;
  }

  static async uploadFile(userId, teamId, file) {
    try {
      let formData = new FormData();
      formData.append("files", file);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams/${teamId}/uploadFile`,
        data: formData,
        headers: {
          ...TeamService.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async uploadAvatar(userId, teamId, file) {
    let formData = new FormData();
    formData.append("files", file);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams/${teamId}/uploadAvatarFile`,
        data: formData,
        headers: {
          ...TeamService.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      return res.data;
    } catch (error) {
      return;
    }
  }

  static async deleteAvatar(userId, teamId, avatar) {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams/${teamId}/deleteAvatarFile`,
        { bucketKey: avatar },
        {
          headers: TeamService.getHeaders(),
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  static async deleteTeam(userId, teamId) {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/teams/${teamId}`,
        {
          headers: TeamService.getHeaders(),
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
