import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    gap: '1rem',
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

DashboardFooter.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function DashboardFooter({ isCollapse }) {

    return (
        <RootStyle
            sx={{
                isCollapse,
            }}
        >
            <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                © 2024 Ecruit All Rights Reserved
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                Ecruit is not endorsed by, directly affiliated with, maintained or sponsored by Apple Inc, Electronic Arts, Activision Blizzard, Take-Two Interactive, Microsoft, Xbox, Sony, Playstation or Epic Games. All content, games titles, trade names and/or trade dress, trademarks, artwork and associated imagery are trademarks and/or copyright material of their respective owners.
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                <Link href="/terms-of-service" color="inherit">
                    Terms of Service
                </Link>
                {' | '}
                <Link href="/privacy-policy" color="inherit">
                    Privacy Policy
                </Link>
            </Typography>
        </RootStyle>
    )
}