import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  Chip,
} from "@mui/material";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

CollegeProfileInfo.propTypes = {
  profile: PropTypes.object,
};

export default function CollegeProfileInfo({ profile }) {
  const {
    collegeType = [],
    desiredDegree = [],
    highSchoolGraduationYear = null,
    gpa = null,
    desiredRegions = [],
    desiredSchools = [],
  } = profile?.collegeDetails || {};

  return (
    <>
      <Card>
        <CardHeader title="Academic Information" />

        <Stack spacing={2} sx={{ p: 3 }}>
          {highSchoolGraduationYear ? (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"ic:round-business-center"} />

              <Typography variant="body2">
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                >
                  Graduating in {highSchoolGraduationYear}
                </Typography>
              </Typography>
            </Stack>
          ) : null}

          {gpa ? (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"ic:round-business-center"} />
              <Typography variant="body2">
                <Link component="span" variant="subtitle2" color="text.primary">
                  GPA: {gpa}
                </Link>
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Card>
      <Card>
        <CardHeader title="College Preferences" />

        <Stack spacing={2} sx={{ p: 3 }}>
          {collegeType?.length ? (
            <>
              <Stack direction="row" alignItems={"center"}>
                <IconStyle icon={"ic:round-business-center"} />
                <Typography sx={{ mr: 1 }} variant="body2">
                  College Type
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {collegeType.map((type) => (
                  <Chip key={type} label={type} sx={{ mr: 0.5, mb: 1 }} />
                ))}
              </Box>
            </>
          ) : null}

          {desiredDegree?.length ? (
            <>
              <Stack direction="row" alignItems={"center"}>
                <IconStyle icon={"ic:round-business-center"} />
                <Typography sx={{ mr: 1 }} variant="body2">
                  Desired Degrees
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {desiredDegree.map((degree) => (
                  <Chip key={degree} label={degree} sx={{ mr: 0.5, mb: 1 }} />
                ))}
              </Box>
            </>
          ) : null}

          {desiredRegions?.length ? (
            <>
              <Stack direction="row" alignItems={"center"}>
                <IconStyle icon={"ic:round-business-center"} />
                <Typography sx={{ mr: 1 }} variant="body2">
                  Desired Regions
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {desiredRegions.map((region) => (
                  <Chip key={region} label={region} sx={{ mr: 0.5, mb: 1 }} />
                ))}
              </Box>
            </>
          ) : null}

          {desiredSchools?.length ? (
            <>
              <Stack direction="row" alignItems={"center"}>
                <IconStyle icon={"ic:round-business-center"} />
                <Typography sx={{ mr: 1 }} variant="body2">
                  Desired Schools
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {desiredSchools.map((school) => (
                  <Chip key={school} label={school} sx={{ mr: 0.5, mb: 1 }} />
                ))}
              </Box>
            </>
          ) : null}
        </Stack>
      </Card>
    </>
  );
}
