import { capitalCase } from "change-case";
import { Container, Tab, Box, Tabs } from "@mui/material";
import { useMemo } from "react";
import PlayerVideoGallery from "../../PlayerVideoGallery";
import useSettings from "src/hooks/useSettings";
import useTabs from "src/hooks/useTabs";
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import NewGameplayUploadsForm from "./NewGameplayUploadsForm";
import useUploads from "src/hooks/useUploads";

const Gallery = ({ profile }) => {
  const { currentTab, onChangeTab, setCurrentTab } = useTabs("gallery");
  const { themeStretch } = useSettings();
  const { uploads, loading, error, reload } = useUploads();

  const ACCOUNT_TABS = useMemo(() => {
    const tabs = [
      {
        value: "gallery",
        icon: <Iconify icon={"mingcute:video-fill"} width={20} height={20} />,
        component: (
          <PlayerVideoGallery
            uploads={uploads}
            loading={loading}
            error={error}
            uploadVideo={() => setCurrentTab("upload video")}
          />
        ),
      },
      {
        value: "upload video",
        icon: <Iconify icon={"eva:video-fill"} width={20} height={20} />,
        component: (
          <NewGameplayUploadsForm
            disabled={loading || uploads.length === 12}
            profile={profile}
            backToGallery={async () => {
              reload();
              setCurrentTab("gallery");
            }}
          />
        ),
      },
    ];

    return tabs;
  }, [profile, setCurrentTab, uploads, loading, error, reload]);

  return (
    <Page title="Player Profile">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs sx={{ mt: 4 }} heading="Video Gallery" />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
          sx={{
            "& .MuiTab-root": {
              padding: "12px 12px", // Adjust padding for better clickability
              justifyContent: "center", // Center the content
            },
          }}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 4 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
};

export default Gallery;
