// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
// utils
// components
import Image from "src/components/Image";

import Avatar from "src/components/Avatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme, cover }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${cover})`,
  backgroundSize: "cover", // Ensure the background image covers the entire area
  backgroundPosition: "center", // Center the background image
  "&:before": {
    backgroundImage: `url(${cover})`,
    backgroundSize: "cover", // Ensure the background image covers the entire area
    backgroundPosition: "center",
    top: 0,
    zIndex: 9,
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PublicTeamCover({
  title = "",
  subtitle = "",
  profile = "",
  cover = "",
}) {
  return (
    <RootStyle
      cover={"/assets/ecruit/profile.webp"}
    >
      <InfoStyle
        sx={{
          width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyItems: "start",
        }}
      >
        <Avatar
          sx={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          src={profile}
          name={subtitle}
        />

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {title && <Typography variant="h4">{title} </Typography>}
          {subtitle && (
            <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
          )}
        </Box>
      </InfoStyle>
      <Image
        alt="profile cover user public"
        src={"assets/ecruit/profile.webp"}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />
    </RootStyle>
  );
}
