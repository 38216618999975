import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Card,
  Stack,
  Autocomplete,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import {
  FormProvider,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import axios from "../../../utils/axios";
import requestHeaders from "src/utils/restClient";

export default function PlayerCollegeForm({ profile, refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const [desiredDegrees, setDesiredDegrees] = useState([]);
  const [desiredSchools, setDesiredSchools] = useState([]);
  const [desiredRegions, setDesiredRegions] = useState([]);
  const [collegeType, setCollegeType] = useState([]);
  const { user } = useAuth();

  const PlayerCollegeFormSchema = Yup.object().shape({
    gpa: Yup.number()
      .typeError("GPA must be a number")
      .min(0, "GPA must be at least 0")
      .max(6, "GPA must be at most 6")
      .test("is-decimal", "GPA must have at most 2 decimal places", (value) => {
        if (value !== undefined && value !== null) {
          return /^(\d+(\.\d{1,2})?)?$/.test(value.toString());
        }
        return true;
      })
      .transform((value, originalValue) => {
        if (typeof originalValue === "string" && originalValue.trim() === "") {
          return null;
        }
        return value;
      })
      .nullable(),
  });

  const defaultValues = {
    visible: profile.collegeDetails?.visible || false,
    highSchoolGraduationYear:
      profile.collegeDetails?.highSchoolGraduationYear || "",
    gpa: profile.collegeDetails?.gpa || "",
    statement: profile.collegeDetails?.statement || "",
    desiredDegree: profile.collegeDetails?.desiredDegree || [],
    collegeType: profile.collegeDetails?.collegeType || [],
    desiredSchools: profile.collegeDetails?.desiredSchools || [],
    desiredRegions: profile.collegeDetails?.desiredRegions || [],
  };

  const methods = useForm({
    resolver: yupResolver(PlayerCollegeFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/players/${profile._id}/college-details`,
        {
          collegeDetails: {
            visible: values.visible,
            highSchoolGraduationYear: values.highSchoolGraduationYear,
            gpa: values.gpa,
            statement: values.statement,
            desiredDegree: values.desiredDegree,
            collegeType: values.collegeType,
            desiredSchools: values.desiredSchools,
            desiredRegions: values.desiredRegions,
          },
        },
        appHeaders
      );
      enqueueSnackbar("Changes have been saved.", {
        autoHideDuration: 2000,
        variant: "success",
      });
      refetch();
    } catch (error) {}
  };

  const years = useMemo(() => {
    // const date = new Date();
    // const year = date.getFullYear();
    // return [year, year + 1, year + 2, year + 3, year + 4, year + 5, year + 6];
    return [
      2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
      2030,
    ];
  }, []);
  useEffect(() => {
    const fetchOptions = async () => {
      const endpoints = [
        "/colleges/types",
        "/colleges/degrees",
        "/colleges/regions",
        "/colleges/options",
      ];
      const [types, degrees, regions, options] = await Promise.all(
        endpoints.map(async (endpoint) =>
          axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            headers: appHeaders,
          })
        )
      );

      setCollegeType(types.data);
      setDesiredDegrees(degrees.data);
      setDesiredRegions(regions.data);
      setDesiredSchools(options.data);
    };
    if (!collegeType.length) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaders]);

  const onError = () => {
    enqueueSnackbar(
      "You have some errors in your form. Please review the highlighted fields above.",
      {
        autoHideDuration: 4000,
        variant: "error",
      }
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
              College Details
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 4, ml: 1 }}>
              Please fill out your college preferences to help us match you with
              the right opportunities. Providing details about your preferred
              colleges, majors, and other relevant information will assist
              college coaches and recruiters in understanding your aspirations
              and how gaming fits into your academic plans.
            </Typography>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                },
              }}
            >
              <RHFSelect
                name={"highSchoolGraduationYear"}
                label="High School Graduation Year"
              >
                <optgroup>
                  <option key={"None"} value={""}></option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </optgroup>
              </RHFSelect>

              <RHFTextField
                name="gpa"
                label="GPA"
                type="number"
                inputProps={{
                  step: "0.01", // Allows increments of 0.01
                  min: 0,
                  max: 6,
                  pattern: "^\\d*(\\.\\d{0,2})?$", // Allows up to 2 decimal places
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    /^\d*(\.\d{0,2})?$/.test(value) &&
                    (value === "" ||
                      (parseFloat(value) >= 0 && parseFloat(value) <= 6))
                  ) {
                    methods.setValue("gpa", value, { shouldValidate: true });
                  }
                }}
              />
            </Box>

            <Stack mt={2}>
              <Controller
                name={`desiredDegree`}
                control={control}
                render={({
                  field: desiredDegreesField,
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={desiredDegrees.map(({ name }) => name)}
                    {...desiredDegreesField}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => desiredDegreesField.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Desired Degrees"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>

            <Stack mt={2}>
              <Controller
                name={`desiredSchools`}
                control={control}
                render={({
                  field: desiredSchoolsField,
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={[
                      ...new Set(desiredSchools.map(({ name }) => name)),
                    ]}
                    {...desiredSchoolsField}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => desiredSchoolsField.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Desired Schools"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Stack mt={2}>
              <Controller
                name={`collegeType`}
                control={control}
                render={({
                  field: desiredCollegeType,
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={collegeType.map(({ type }) => type)}
                    {...desiredCollegeType}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => desiredCollegeType.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="College Type"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Stack mt={2}>
              <Controller
                name={`desiredRegions`}
                control={control}
                render={({
                  field: desiredRegionField,
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={desiredRegions.map(({ name }) => name)}
                    {...desiredRegionField}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => desiredRegionField.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Desired Region"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500", mt: 2, ml: 1, mb: 1 }}
            >
              About Me
            </Typography>
            <Typography sx={{ mt: 1, ml: 1 }} variant="subtitle2">
              Please write a short essay explaining how your college aspirations
              relate to gaming. This is your chance to share your goals,
              passion, and how gaming is an integral part of your future plans.
              College coaches and recruiters will read this to understand your
              dedication and vision.
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 1 }}>
              <RHFEditor name="statement" label={"About Me"} />
            </Stack>
            <Stack
              spacing={3}
              alignItems="flex-end"
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{ mt: 1 }}
            >
              <Stack>
                <Controller
                  name={`visible`}
                  control={control}
                  label="Loading"
                  render={({
                    field: desiredRegionField,
                    fieldState: { error },
                  }) => (
                    <FormControlLabel
                      sx={{
                        display: "block",
                        width: "fit-content",
                      }}
                      control={
                        <Switch
                          checked={desiredRegionField.value}
                          onChange={(e, checked) =>
                            desiredRegionField.onChange(checked)
                          }
                          name="loading"
                          color="primary"
                        />
                      }
                      label="Visible to recruiters"
                    />
                  )}
                />
                <Typography variant="body2">
                  Turn the toggle on to make your profile visible to college
                  coaches and recruiters.
                </Typography>
              </Stack>

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                sx={{ width: "fit-content" }}
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
