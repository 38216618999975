import React from "react";
import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const LocationCard = ({ city, country, state }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await GetCountries();
      setCountries(countries);
    };
    if (!countries.length) {
      fetchCountries();
    }
  }, [countries.length]);

  useEffect(() => {
    const fetchStates = async () => {
      const countryFound = countries.find(({ name }) => name === country);
      if (countryFound) {
        const states = await GetState(countryFound.id);
        setStates(states);
      }
    };
    if (country) {
      fetchStates();
    }
  }, [country, countries]);

  useEffect(() => {
    const fetchCities = async () => {
      const countryFound = countries.find(({ name }) => name === country);
      const stateFound = states.find(({ name }) => name === state);
      if (stateFound && countryFound) {
        const cities = await GetCity(countryFound.id, stateFound.id);
        setCities(cities);
      }
    };
    if (country && state) {
      fetchCities();
    }
  }, [country, state, states, countries]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: "500", mb: 2.5, ml: 1 }}>
        Location
      </Typography>
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
        }}
      >
        <RHFSelect name={`locationCountryCode`} label="Country">
          <optgroup>
            <option key={"None"} value={""}></option>
            {countries.map((country) => (
              <option key={country.id} value={country.name}>
                {country.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>

        <RHFSelect
          name={`locationState`}
          label={country === "United States" ? "State" : "Region"}
        >
          <optgroup>
            <option key={"None"} value={""}></option>
            {states.map((state) => (
              <option key={state.id} value={state.name}>
                {state.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>
        <RHFSelect name={`locationCity`} label="City">
          <optgroup>
            <option key={"None"} value={""}></option>
            {cities.map((cities) => (
              <option key={cities.id} value={cities.name}>
                {cities.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>
        {country === "United States" && (
          <RHFTextField
            inputProps={{
              maxLength: 5, // Limit the length of the zip code to 5 characters
              onInput: (e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              },
            }}
            name="locationZipCode"
            label="Zipcode"
          />
        )}
      </Box>
    </>
  );
};

export default LocationCard;
