/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useState, useEffect, useCallback, } from "react";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import requestHeaders from "src/utils/restClient";
import axios from "src/utils/axios";
import {
  Card,
  Grid,
  Stack,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFUploadSingleFile,
  RHFTextFieldMultiline,
  RHFCheckbox,
} from "src/components/hook-form";
import { SnackbarProvider, useSnackbar } from "notistack";
import { PlayerService } from "src/services/player";
import useAuth from "src/hooks/useAuth";


export default function NewGameplayUploadsForm({ profile, backToGallery, disabled }) {
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));

  const [games, setGames] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth()
  const userId = user._id

  const NewProductSchema = Yup.object().shape({
    uploadGame: Yup.string().required("Game selection required"),
    list: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          fileName: Yup.string().required("Upload Name is required"),
          fileDescription: Yup.string().max(500, "Description cannot be greater than 500 characters"),
          featured: Yup.boolean(),
          img: Yup.mixed().required("Image is required"),
        })
      )
      .test(
        "unique-game",
        "Each game should be unique - only 1 entry per game is allowed.",
        (list) => {
          const games = list.map((item) => item.name).filter((game) => game);
          return games.length === new Set(games).size;
        }
      )
  });





  const fetchGames = useCallback(async () => {
    const games = await PlayerService.getGames();
    if (games) {
      setGames(games)
    }
  }, [])


  useEffect(() => {
    fetchGames();
  }, [])

  const defaultValues = {
    uploadGame: "",
    gameUserName: "",
    list: [{
      id: uuidv4(),
      fileName: "",
      fileDescription: "",
      featured: true,
      img: null
    }],
  };

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
    mode: "onBlur"
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  //SINGLE FILE UPLOAD
  async function handleUploadSingleFile(file, index) {
    if (file["img"] !== undefined) {
      let formData = new FormData();
      formData.append("files", file["img"]);

      try {
        const res = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/uploadFile`,
          data: formData,
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "multipart/form-data",
          },
        });
        return [res.data.fileLocation, index, file];
      } catch (error) {
        return ["Upload Error: " + file["img"].filename, index, file];
      }
    } else {
      return ["", index, file];
    }
  }

  const [videoTutorial, setVideoTutorial] = useState(null);

  async function downloadTutorialVideo() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/downloadTutorialVideo`,
        {},
        appHeaders
      );
      setVideoTutorial(res.data.url);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    downloadTutorialVideo();
  }, []);

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      for await (const uploadUrl of values.list.map((video, index) =>
        handleUploadSingleFile(video, index)
      )) {
        if (uploadUrl[0].includes("Upload Error") === false) {
          if (uploadUrl[0] !== "") {
            const postData = {
              contentFile: uploadUrl[0],
              name: uploadUrl[2]["img"]["name"],
              gameUserName: values.gameUserName,
              game: values.uploadGame,
              featured: uploadUrl[2]["featured"],
              customName: uploadUrl[2]["fileName"],
              description: uploadUrl[2]["fileDescription"],
            };
            try {
              await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads`,
                postData,
                appHeaders
              );
              enqueueSnackbar("Video saved: " + uploadUrl[2]["img"]["name"], {
                autoHideDuration: 4000,
                variant: "success",
              });
            } catch (error) {
              console.error(error);
              enqueueSnackbar(
                "Upload Failed for " + uploadUrl[2]["img"]["name"],
                {
                  autoHideDuration: 4000,
                  variant: "error",
                }
              );
            }
          } else {
            enqueueSnackbar(
              "No file selected " +
              (uploadUrl[2]["fileName"] !== null
                ? "for " + uploadUrl[2]["fileName"]
                : ""),
              {
                autoHideDuration: 4000,
                variant: "error",
              }
            );
          }
        } else {
          enqueueSnackbar("Upload Failed for " + uploadUrl[2]["img"]["name"], {
            autoHideDuration: 4000,
            variant: "error",
          });
        }
      }

      reset();
      backToGallery();
    } catch (error) {
      enqueueSnackbar("Upload Failed.", {
        autoHideDuration: 4000,
        variant: "error",
      });
      console.error(error);
    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>

          {disabled && <Alert sx={{ mb: 2 }} severity="info">You have reached the upload limit of 12 videos</Alert>}
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <RHFSelect
                  sx={{ mt: 3, mb: 1.5 }}
                  name={"uploadGame"}
                  label="Associated Game"
                  onChange={e => {
                    setValue("uploadGame", e.target.value)
                    const gameFound = profile?.games.find(game => game.name === e.target.value)
                    if (gameFound) {
                      setValue("gameUserName", gameFound.gamerId);
                    }

                  }}
                >
                  <optgroup>
                    <option key="Select Game" value="" ></option>
                    {games.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </optgroup>
                </RHFSelect>

                <RHFTextField
                  name="gameUserName"
                  sx={{ mt: 1.5, mb: 3 }}
                  label="Your Gamer ID"
                />
                <Typography variant="body1" sx={{ ml: 1, mb: 3 }}>
                  <strong>Select Upload</strong>
                </Typography>
                <ul>
                  <Box sx={{ overflowY: "scroll", maxHeight: "800px" }}>
                    <Controller
                      name={"list"}
                      control={control}
                      render={({ field, fieldState: { error, } }) => (
                        <>
                          {field.value.map((item, indexOf) => (
                            <Controller
                              name={`list.${indexOf}`}
                              control={control}
                              render={({ field, fieldState: { error, } }) => (
                                <VideoUploader field={field} index={indexOf} item={item} error={error} control={control} />)}
                            />
                          ))}
                        </>
                      )} />


                  </Box>
                </ul>
              </div>
            </Stack>
          </Card>
          <Stack sx={{ mt: 3 }} spacing={3}>
            <SnackbarProvider />
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
              disabled={disabled}
            >
              {"Submit"}
            </LoadingButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ ml: 1, fontSize: "14px" }} variant="overline">How to upload videos to your profile</Typography>
          <Stack sx={{ ml: 1, mt: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
            {videoTutorial !== null && <video width="100%" controls controlsList="nodownload">
              <source src={videoTutorial} type="video/mp4" />
            </video>}
          </Stack>

        </Grid>
      </Grid>
    </FormProvider>
  );
}


const VideoUploader = ({ field, index,  item }) => {




  const handleDropUpload = (acceptedFiles) => {
    let file = acceptedFiles[0];

    if (file) {
      if (file.type.includes("video/")) {
        file = Object.assign(file, {
          preview: "/assets/videofileuploaded.jpg",
        })
      } else {
        file = Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      }
      field.onChange({
        ...field.value,
        img: file,
      })
    }
  };


  return (
    <Box
      key={item.id}
      sx={{ mt: 2 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={10} lg={10}>
        <RHFUploadSingleFile
          accept="video/mp4, video/mpeg, video/quicktime, video/x-msvideo,"
          name={`list.${index}.img`}
          maxSize={2500000000} //25 MB
          value={field.value.img}
          onDrop={(accepted, rejected) => {
            handleDropUpload(accepted, rejected, item.id);
          }}
        />
        <Typography sx={{ mt: 1 }}>Max upload size: 25 MB</Typography>

        <RHFTextField
          sx={{ mt: 3, mb: 3 }}
          label="Upload Name"
          name={`list.${index}.fileName`}
        />

        <RHFTextFieldMultiline
          sx={{ mt: 0, mb: 1 }}
          label="Upload Description"
          name={`list.${index}.fileDescription`}
        />
        <RHFCheckbox
          name={`list.${index}.featured`}
          label={
            <Typography variant="body2" fontWeight={"semi-bold"} align="left">
              Featured
            </Typography>
          }
        />

      </Grid>
    </Box>
  )
} 