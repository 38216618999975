// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container, Stack } from "@mui/material";
// routes
// hooks
import useAuth from "../../../hooks/useAuth";
import useTabs from "../../../hooks/useTabs";
import useSettings from "../../../hooks/useSettings";
// _mock_
// components
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
// sections
import { Profile } from "../../../sections/@dashboard/user/profile";
import { useMemo } from "react";
import PlayerProfileInfo from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerProfileInfo";
import PlayerProfileBio from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerProfileBio";
import CollegeProfileInfo from "src/sections/@dashboard/user/profile/MyCollegeProfile/CollegeProfileInfo";
import CollegeProfileStatement from "src/sections/@dashboard/user/profile/MyCollegeProfile/CollegeProfileStatement";
import Gallery from "./gallery";
import Teams from "./teams";
import Loader from "../Loader";
import usePublicPlayerProfile from "src/hooks/usePublicPlayerProfile";
import { useParams } from "react-router";
import ProfileCover from "./cover";
import useTeams from "src/hooks/useTeams";
import useSquads from "src/hooks/useSquads";
import Image from "src/components/Image";
import PlayerStats from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerStats";
import uuidv4 from "src/utils/uuidv4";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PublicPlayerProfile() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const { id } = useParams();
  const { currentTab, onChangeTab } = useTabs("Player Profile");
  const { profile, fetching, videos, refetch } = usePublicPlayerProfile(id);

  const { teams: teamsList } = useTeams();
  const { squads: squadsList } = useSquads();

  const PROFILE_TABS = useMemo(() => {
    const tabs = [
      {
        value: "Player Profile",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: fetching ? (
          <></>
        ) : (
          <Profile
            leftNav={<PlayerProfileInfo profile={profile} />}
            main={<PlayerProfileBio key={uuidv4()}  profile={profile} videos={videos} />}
          />
        ),
      },
      {
        value: "Video Gallery",
        icon: <Iconify icon={"mingcute:video-fill"} width={20} height={20} />,
        component: (
          <Loader loading={fetching}>
            <Gallery profile={profile} uploads={profile?.uploads || []} />
          </Loader>
        ),
      },
      {
        value: "Teams I'm On",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-regular"}
            width={20}
            height={20}
          />
        ),
        component: <Teams isPublicProfile={true} teams={profile?.teamsImOn} />,
      },
    ];

    if (profile?.collegeDetails) {
      tabs.splice(1, 0, {
        value: "My College Profile",
        icon: <Iconify icon={"mdi:college-outline"} width={20} height={20} />,
        component: (
          <Profile
            leftNav={<CollegeProfileInfo profile={profile} />}
            main={<CollegeProfileStatement profile={profile} />}
          />
        ),
      });
    }

    if (profile && profile.games && profile.games.some(game => game.name === 'League of Legends')) {
      tabs.splice(2, 0, {
        value: "Game Stats",
        icon: <Iconify icon={"gridicons:stats-down"} width={20} height={20} />,
        component: (
          <PlayerStats
            profile={profile}
          />
        ),
      });
    }

    return tabs;
    // eslint-disable-next-line
  }, [fetching, profile, videos, user.goals]);

  return (
    <Page title="Public Player Profile">
      <Loader loading={fetching}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Stack direction="row">
            <Image
              alt="player animal"
              src={"/assets/ecruit/player-profile.png"}
              sx={{ width: 80, height: 80, mr: 1, mt: 3 }}
            />
            <HeaderBreadcrumbs
              sx={{ mt: 5, mb: 0 }}
              heading="Public Player Profile"
              links={[
              ]}
            />
          </Stack>
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <ProfileCover
              teams={profile?.teamsImOn}
              teamsList={teamsList}
              player={profile}
              profile={profile?.avatarUrl}
              title={
                profile?.firstName
                  ? `${profile?.firstName} ${profile?.lastName || ""}`
                  : profile?.username
              }
              subtitle={profile?.firstName ? profile?.username : ""}
              squadsList={squadsList}
              squads={profile?.squadsImOn}
              refetchSquad={refetch}
              refetchTeam={refetch}
            />

            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
                sx={{
                  '& .MuiTab-root': {
                          // minWidth: 'auto', // Ensure auto width for the tabs
                          padding: '12px 12px', // Adjust padding for better clickability
                          justifyContent: 'center', // Center the content
                        },
                }}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}
