function path(root, sublink) {
  return `${root}${sublink}`;
}

function flat(object) {
  let values = Object.values(object);
  let results = [];
  for (let value of values) {
    if (typeof value === "string") {
      results.push(value);
    } else {
      const result = flat(value);
      results.push(...result);
    }
  }
  return results.flat();
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, "/app"),
  uploads: {
    root: path(ROOTS_DASHBOARD, "/uploads"),
    new: path(ROOTS_DASHBOARD, "/uploads/new"),
  },
  search: path(ROOTS_DASHBOARD, "/search"),
  players: path(ROOTS_DASHBOARD, "/players"),
  public: {
    player: (id) => path(ROOTS_DASHBOARD, `/public/player/${id}`),
    user: (id) => path(ROOTS_DASHBOARD, `/public/user/${id}`),
    organization: (id) => path(ROOTS_DASHBOARD, `/public/organization/${id}`),
    team: (id) => path(ROOTS_DASHBOARD, `/public/team/${id}`),
  },
  teams: {
    root: path(ROOTS_DASHBOARD, "/teams"),
    view: (id) => path(ROOTS_DASHBOARD, `/teams/${id}`),
    new: path(ROOTS_DASHBOARD, "/teams/new"),
  },
  squads: {
    root: path(ROOTS_DASHBOARD, "/squads"),
    view: (id) => path(ROOTS_DASHBOARD, `/squads/${id}`),
    new: path(ROOTS_DASHBOARD, "/squads/new"),
  },
  profile: {
    player: {
      root: path(ROOTS_DASHBOARD, "/player"),
      viewTeam: (id) => path(ROOTS_DASHBOARD, `/player/team/${id}`),
    },
    organization: {
      root: path(ROOTS_DASHBOARD, "/organization"),
      viewTeam: (id) => path(ROOTS_DASHBOARD, `/organization/team/${id}`),
    },
  },

  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    viewProfileUpload: (userId, uploadId) =>
      path(ROOTS_DASHBOARD, `/user/${userId}/uploads/${uploadId}`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  }
};

export const AUTH_REQUIRED_ROUTES = flat([
  PATH_DASHBOARD
]);