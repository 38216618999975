import { Card, Typography, CardHeader, Stack, Grid } from "@mui/material";
import Avatar from "src/components/Avatar";

const EsportsTitles = ({ esportsTitles = [] }) => {
  return (
    <Card>
      <CardHeader title="Esports Titles" />
      <Stack spacing={2} sx={{ p: 3 }}>
        {esportsTitles.map(({ logoUrl, name, _id }) => (
          <Grid
            container
            alignItems={"center"}
            flex
            flexDirection={"row"}
            key={_id}
          >
            <Grid item>
              <Avatar src={logoUrl} name={name} />
            </Grid>
            <Grid item xs={10} sx={{ ml: 1 }}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="subtitle1">{name}</Typography>
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Card>
  );
};

export default EsportsTitles;
