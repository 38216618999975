import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "../../../utils/axios";
import requestHeaders from "src/utils/restClient";
import { emailAlreadyRegistered } from "src/utils/validationRequest";
import useAuth from "src/hooks/useAuth";

export default function InviteUserForm() {
  const isMountedRef = useIsMountedRef();
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const { user } = useAuth();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address.")
      .required("Email is required.")
      .test(
        "is-available",
        "This email is already taken by an existing user or pending invitation.",
        async (email) => {
          if (!email) return false; // Return false if value is empty
          return await emailAlreadyRegistered(email);
        }
      ),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [formSubmitted, setFormSubmitted] = useState(false);

  //Display the success alert for 3 seconds after submit
  useEffect(() => {
    if (formSubmitted === true) {
      new Promise((resolve) => setTimeout(resolve, 3000));
      return () => {
        setFormSubmitted(false);
      };
    }
  }, [formSubmitted]);

  let inviter = "";
  if (user.email) {
    inviter = user.email;
  }
  if (user.firstName || user.lastName) {
    let userName =
      " (" + (user.firstName || "") + " " + (user.lastName || "") + ")";
    inviter += userName;
  }

  const onSubmit = async (data) => {
    const postData = {
      email: data.email,
      inviter: inviter.trim(),
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/invites`,
        postData,
        appHeaders
      );
      reset();
      setFormSubmitted(true);
    } catch (error) {
      setFormSubmitted(false);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ mt: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">
            Could not issue invite. Please try again.
          </Alert>
        )}
        {formSubmitted && (
          <Alert severity="success">Invite has been sent.</Alert>
        )}

        <RHFTextField name="email" label="Email address" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Send Invite
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
