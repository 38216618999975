import PropTypes from "prop-types";
import { Stack, InputAdornment, TextField, Button } from "@mui/material";
import Iconify from "src/components/Iconify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

SearchToolbarNav.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
};

export default function SearchToolbarNav({ filterName, onFilterName, handleSearch }) {

  // Function to handle "Enter" key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        spacing={2}
        direction="row"
        sx={{ py: 2.5, flexGrow: 1 }}
      >

        <TextField
          fullWidth
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Search Ecruit..."
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "14px !important",
              py: 2,
            
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" onClick={handleSearch}>Search</Button>
      </Stack>
    </LocalizationProvider>
  );
}
