import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";


const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  REGISTERVIAINVITE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  connectDiscord: () => Promise.resolve(),
  registerViaInvite: () => Promise.resolve(),
  updateGeneralSettings: () => Promise.resolve(),
  updateProfileSettings: () => Promise.resolve()
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const userId = localStorage.getItem("_id");

          const config = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: localStorage.getItem("accessToken"),
            },
          };

          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
            config
          );
          const user = response.data;
          localStorage.setItem("profileImage", user.avatarUrl);

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/login`,
      {
        email,
        password,
      }
    );
    const accessToken = response.data.token;
    const user = response.data;

    setSession(accessToken);
    localStorage.setItem("_id", user["_id"]);
    localStorage.setItem("profileImage", user.avatarUrl);
    localStorage.setItem("permissions", user.role.permissions);
    localStorage.setItem("roleName", user.role.name);
    localStorage.setItem("firstName", user.firstName);
    localStorage.setItem("lastName", user.lastName);
    localStorage.setItem("username", user.username);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };


  const registerViaInvite = async (
    firstName,
    lastName,
    email,
    username,
    password,
    terms,
    dob,
    gender,
    phone,
    key
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/register-via-invite`,
      firstName,
      lastName,
      email,
      username,
      password,
      terms,
      dob,
      gender,
      phone,
      key
    );

    const accessToken = response.data.token;
    const user = response.data.user;

    setSession(accessToken);
    localStorage.setItem("_id", user["_id"]);
    localStorage.setItem("profileImage", user.avatarUrl);
    localStorage.setItem("permissions", user.role.permissions);
    localStorage.setItem("roleName", user.role.name);
    localStorage.setItem("firstName", user.firstName);
    localStorage.setItem("lastName", user.lastName);
    localStorage.setItem("username", user.username);

    dispatch({
      type: "REGISTERVIAINVITE",
      payload: {
        user,
      },
    });
  };

  const register = async ({
    firstName,
    lastName,
    email,
    username,
    password,
    terms,
    dob,
    gender,
    phone
  }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/register`,
      { firstName, lastName, email, username, password, terms, dob, gender, phone }
    );
    const accessToken = response.data.token;
    const user = response.data;

    setSession(accessToken);
    localStorage.setItem("_id", user["_id"]);
    localStorage.setItem("profileImage", user.avatarUrl);
    localStorage.setItem("permissions", user.role.permissions);
    localStorage.setItem("roleName", user.role.name);
    localStorage.setItem("firstName", user.firstName);
    localStorage.setItem("lastName", user.lastName);
    localStorage.setItem("username", user.username);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const connectDiscord = async () => {
    const token = localStorage.getItem("accessToken");
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/discord?token=${token}`;
  }


  const logout = async () => {
    setSession(null);

    // redirect to homepage
    localStorage.removeItem("accessToken");

    // Remove all other local storage 
    localStorage.removeItem("profileImage");
    localStorage.removeItem("_id");
    localStorage.removeItem("permissions");
    localStorage.removeItem("roleName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("username");
    localStorage.removeItem("organizationProfileImage");
    localStorage.removeItem("playerProfileImage");
    localStorage.removeItem("fullName");
    localStorage.removeItem("goals");

    dispatch({ type: "LOGOUT" });
  };

  const updateGeneralSettings = async ({ email, username, phone, avatar, firstName, lastName }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${state.user?._id}`,
        { email, username, phone: phone?.replaceAll(" ", ""), avatar, firstName, lastName }
      );
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return { error }
    }
  }


  const updateProfileSettings = async ({ bio, type, instagram, twitter, isPublic, facebook, threads, avatar, games, city, country, gamerTags, zipcode, userState }, profile) => {
    const filteredGames = games.filter(game => (game.name || game.gamerId || game.positions.length)).map(game => ({
      ...game,
      gamerId: removeAtSymbol(game.gamerId?.trim()),
    }));

    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/users/${state.user?._id}/players/${profile?._id}`,
      {
        bio, socials: { instagram: instagram || null, twitter: twitter || null, facebook: facebook || null, threads: threads || null }, avatar, games: filteredGames,
        locationZipCode: (country === "United States" ? zipcode : "") || null,
        locationCountryCode: country || null,
        locationState: country ? userState || null : null,
        locationCity: country && userState ? city || null : null,
        gamerTags,
        type,
        isPublic
      }, {
      withCredentials: true
    }
    ).catch((err) => {
      return { error: err }
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        connectDiscord,
        registerViaInvite,
        updateGeneralSettings,
        updateProfileSettings
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

function removeAtSymbol(str) {
  if (str.startsWith('@')) {
    return str.slice(1);
  }
  return str;
}
