import { Card, Typography, Stack, Link, Grid,  Chip, Tooltip, IconButton } from "@mui/material";
import { useMemo, useState } from "react";
import Iconify from "src/components/Iconify";
import DeleteDialog from "../../DeleteDialog";
import axios from "src/utils/axios";
import { useSnackbar } from "notistack";
import AddToTeamDialog from "src/components/dialog/AddToTeamDialog";
import Avatar from "src/components/Avatar";

// components

export const PlayersTeamsInfo = ({ teams, player , refetch, teamsList}) => {
    const [open, setOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isDeleteModalOpen, setIsDeleteDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const openDeleteModal = (team) => {
        setSelectedTeam(team);
        setIsDeleteDialogOpen(true)
    }

    const filteredTeams = useMemo(() => {
        let teamsMap = {};
        teams.forEach(team => {
            teamsMap[team.id] = true;
        });
        return teamsList.filter(team => !teamsMap[team._id])
        // eslint-disable-next-line 
    },[teamsList, teams])


    const handleDelete = async () => {
        try {
            await axios.delete(`/teams/${selectedTeam.id}/players/${player._id}`);
            refetch()
            enqueueSnackbar("Player removed from team successfully.", {
                autoHideDuration: 4000,
                variant: "success",
            });
        }
        catch (err) {
            enqueueSnackbar("Error removing player from team.", {
                autoHideDuration: 4000,
                variant: "error",
            });
        }
        setIsDeleteDialogOpen(false);
    }
    return <Card>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ pt: 1, px: 3 }}>
            <Typography variant="subtitle1" fontWeight={"bold"}>Player Teams</Typography>
            <Tooltip title="Invite to Team">
                <IconButton onClick={() => setOpen(true)}>
                    <Iconify icon={"flat-color-icons:plus"} color="#44931C" width={20} height={20} />
                </IconButton>
            </Tooltip>
        </Stack>

        <Stack spacing={2} sx={{ p: 3, pt: 1 }}>
            {teams.map(team => <PlayerTeamInfo key={team.id} team={team} openDeleteModal={openDeleteModal} />)}
        </Stack>
        <AddToTeamDialog open={open} handleClose={() => setOpen(false)} teamsList={filteredTeams} player={player} refetch={refetch}/>
        {selectedTeam &&
            <DeleteDialog
                title={`Are you sure you want to remove this player from the ${selectedTeam.name} team?`}
                open={isDeleteModalOpen}
                handleConfirm={handleDelete}
                handleClose={() => setIsDeleteDialogOpen(false)}
            />}
    </Card>
}


export const PlayerTeamInfo = ({ team, openDeleteModal }) => {
    return (
        <Stack key={team._id}>
            <Stack direction={"row"} alignItems={"center"} justifyContent="space-between">
                <Grid
                    container
                    alignItems={"center"}
                    flex
                    flexDirection={"row"}
                    gap={1.5}
                    key={team._id}
                >
                    <Grid item>
                        <Avatar src={team.logoUrl} name={team.name}/>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} fle>
                            <Link
                                component="a"
                                variant="subtitle2"
                                color="text.primary"
                                noWrap
                                href={`/dashboard/teams/${team.id}`}
                            > <Typography variant="subtitle2">{team.name}</Typography>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
                <IconButton
                    onClick={() => openDeleteModal(team)}
                >
                    <Iconify icon={"lets-icons:remove-fill"} color="#E36A52" width={20} height={20} />

                </IconButton>
            </Stack>

            <Stack direction={"row"} flexWrap={"wrap"} sx={{ mt: 1 }}>
                {team?.positions.map(position => {
                    const { positions, game } = position;
                    const positionsDetails = positions.length ? positions.join(" ,") : "No positions";
                    return <Chip key={position._id} sx={{ mt: 1 }} label={`${game.name}: ${positionsDetails}`}></Chip>
                })}
            </Stack>

        </Stack>
    )
}