import { useSnackbar } from "notistack";
import { useCallback, useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Button,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../../hooks/useAuth";
import { fData } from "../../../../utils/formatNumber";
import {
  FormProvider,
  RHFCheckbox,
  RHFEditor,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from "../../../../components/hook-form";
import axios from "../../../../utils/axios";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import requestHeaders from "src/utils/restClient";
import { styled } from "@mui/material/styles";
import Iconify from "src/components/Iconify";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Validation } from "src/utils/validations";
import { PlayerService } from "src/services/player";
import { useLocation } from "react-router";

export default function PlayerAccountGeneral({ profile, refetch }) {
  const IconStyle = styled(Iconify)(({ theme }) => ({
    width: 20,
    height: 20,
    marginTop: 1,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  }));

  const { enqueueSnackbar } = useSnackbar();

  const [userUrl, setUserUrl] = useState(null);
  const [gamerTags, setGamerTags] = useState([]);
  const [games, setGames] = useState([]);
  const [positions, setPositions] = useState(profile?.games ? profile.games.map(g => ({ game: g.name, positions: g.positions })) : []);
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const isMountedRef = useIsMountedRef();

  const { user, updateProfileSettings } = useAuth();

  const profilePhotoExists = useMemo(() => {
    return !!profile?.avatar;
  }, [profile?.avatar]);

  const defaultValues = {
    gamerTags: profile?.gamerTags || [],
    games: profile?.games
      ? profile.games
      : [
        {
          name: "",
          gamerId: "",
          positions: [],
        },
      ],
    country: profile?.locationCountryCode || "",
    state: profile?.locationState || "",
    city: profile?.locationCity || "",
    zipcode: profile?.locationZipCode || "",
    instagram: profile?.socials?.instagram || "",
    twitter: profile?.socials?.twitter || "",
    facebook: profile?.socials?.facebook || "",
    threads: profile?.socials?.threads || "",
    photoURL: profile?.avatarUrl || "",
    bio: profile?.bio || "",
    type: profile?.type || "",
    avatar: profile?.avatar || "",
    isPublic: profile?.isPublic !== undefined ? profile?.isPublic : true,
  };

  const methods = useForm({
    resolver: yupResolver(Validation.PlayerAccountValidationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    control,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    const errorsKeys = Object.keys(errors);
    if (errorsKeys.length) {
      enqueueSnackbar(`${errors[errorsKeys[0]].message}`, {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  }, [errors, isSubmitting, enqueueSnackbar]);
  useEffect(() => {
    getUserImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      userUrl !== null &&
      userUrl !== undefined &&
      userUrl !== "" &&
      userUrl !== "undefined"
    ) {
      setValue("photoURL", userUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUrl]);

  //SINGLE FILE UPLOAD
  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false);
  async function handleUpload(uid, fileType) {
    setUploading(true);
    let formData = new FormData();
    formData.append("files", fileType);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/users/${uid}/players/${profile._id}/uploadAvatarFile`,
        data: formData,
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      setUploading(false);
      return res.data;
    } catch (error) {
      setUploading(false);
      console.log("Error uploading file: " + error);
      return;
    }
  }

  const [profileImageUpdated, setProfileImageUpdated] = useState(false);
  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      let profileImageLocation;
      let photoDeleteConfirmed;
      let avatarUrl = values.avatar;

      values.games = values.games.map(game => {
        game.positions = positions.find(p => p.game === game.name)?.positions || [];
        return game;
      });

      if (values.photoURL !== null) {
        //Upload profile image if it was updated
        if (profileImageUpdated && values.photoURL.path !== undefined) {
          const { fileLocation, signedFileUrl } = await handleUpload(
            user._id,
            values.photoURL
          );
          avatarUrl = fileLocation;
          profileImageLocation = signedFileUrl;
          localStorage.setItem("playerProfileImage", signedFileUrl);
        } else {
          profileImageLocation = profile?.avatar;
        }
      } else {
        profileImageLocation = null;
      }

      if (profilePhotoExists === true && profileImageUpdated) {
        //need to remove the old image file from S3bucket
        await removeOldUserPhoto();
        photoDeleteConfirmed = "File Deleted";
      } else {
        photoDeleteConfirmed = "File Deleted";
      }

      await patchUser(profileImageLocation, photoDeleteConfirmed);

      const res = await updateProfileSettings(
        {
          ...values,
          avatar: avatarUrl,
          userState: values.state,
        },
        profile
      );

      if (!res || res.error) {
        return enqueueSnackbar(!res ? 'Something went wrong' : res.error, {
          autoHideDuration: 8000,
          variant: "error",
        })
      }

      await PlayerService.patchPublicSettingsById(
        user._id,
        profile._id,
        values.isPublic
      );
      enqueueSnackbar("Changes have been saved.", {
        autoHideDuration: 2000,
        variant: "success",
      });

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const patchUser = async (userImage, imageDeleteConfirmed) => {
    localStorage.removeItem("playerProfileImage");
    localStorage.setItem("playerProfileImage", userImage);

    if (imageDeleteConfirmed === "File Deleted") {
      try {
        await updateProfileSettings(values, profile);
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setError("afterSubmit", { ...error, message: error.message }); //Show an alert box if state errors
        }
      }
    }
  };

  const removeOldUserPhoto = useCallback(async () => {
    const postData = {
      bucketKey: profile?.avatar,
    };
    if (
      profile?.avatar !== "undefined" &&
      profile?.avatar !== undefined &&
      profile?.avatar !== "" &&
      profile?.avatar !== null
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/players/${profile._id}/deleteAvatarFile`,
          postData,
          appHeaders
        );
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, appHeaders]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "photoURL",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setProfileImageUpdated(true);
      }
    },
    [setValue]
  );

  const getUserImage = useCallback(async () => {
    const postData = {
      bucketKey: profile?.avatar,
    };
    if (
      profile?.avatar !== "undefined" &&
      profile?.avatar !== undefined &&
      profile?.avatar !== "" &&
      profile?.avatar !== null
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/downloadFile`,
          postData,
          appHeaders
        );

        if (isMountedRef.current) {
          setUserUrl(response.data.url);
          return response.data.url;
        }
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, appHeaders]);

  useEffect(() => {
    fetchgamerTags(setGamerTags);
    fetchGames(setGames);
  }, []);

  const onError = () => {
    enqueueSnackbar(
      "You have some errors in your form. Please review the highlighted fields above.",
      {
        autoHideDuration: 4000,
        variant: "error",
      }
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: "center", height: 1 }}>
            <RHFUploadAvatar
              name="photoURL"
              accept="image/*"
              maxSize={1048576}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: "auto",
                    display: "block",
                    textAlign: "center",
                    color: "text.secondary",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp
                  <br /> max size of {fData(1048576)}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
              Info
            </Typography>
            <Stack spacing={3} marginBottom={3}>
              <Controller
                name={`gamerTags`}
                control={control}
                render={({ field: gamerTagsField, fieldState: { error } }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={gamerTags.map(({ name }) => name)}
                    {...gamerTagsField}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => gamerTagsField.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gamer Tags"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Stack spacing={3} marginBottom={3}>
              <Controller
                name={`type`}
                control={control}
                render={({ field: gamerTypeField, fieldState: { error } }) => (
                  <Autocomplete
                    id="player-type-tags"
                    options={playerTypes}
                    {...gamerTypeField}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(_, data) => gamerTypeField.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Player Type"
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <LocationCard
              city={values.city}
              state={values.state}
              country={values.country}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
              Socials
            </Typography>

            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                },
              }}
            >
              <RHFTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconStyle icon={"prime:twitter"} color="#1C9CEA" />
                    </InputAdornment>
                  ),
                }}
                name="twitter"
                label="Twitter"
              />
              <RHFTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconStyle
                        icon={"ant-design:instagram-filled"}
                        color="#D7336D"
                      />
                    </InputAdornment>
                  ),
                }}
                name="instagram"
                label="Instagram"
              />
              <RHFTextField
                name="threads"
                label="Threads"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconStyle
                        icon={"mingcute:threads-fill"}
                        color="#ffffff"
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="facebook"
                label="Facebook"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconStyle icon={"eva:facebook-fill"} color="#1877F2" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 1, ml: 1 }}>
              Games I Play
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1, ml: 1 }}>
              Providing your Gamer ID is optional, but highly recommended.
              Without it, your profile will be harder to find for other gamers
              and recruiters, and we will be unable to connect it to
              scoreboards.
            </Typography>
            <Controller
              name={"games"}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      rowGap: 3,
                      columnGap: 2,
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(3, 1fr)",
                      },
                      mt: 3,
                    }}
                  >
                    <>
                      {field.value.map((value, index) => (
                        <GameOptionSelector
                          field={field}
                          games={games}
                          index={index}
                          key={index}
                          control={control}
                          positions={positions}
                          setPositions={setPositions}
                          {...fieldState}
                        />
                      ))}
                    </>
                  </Box>
                  {fieldState.error?.message && (
                    <Typography sx={{ color: "error.main", mt: 1 }}>
                      {fieldState.error?.message}
                    </Typography>
                  )}
                  <Stack
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 2, color: "white" }}
                      onClick={(e) =>
                        field.onChange([
                          ...field.value,
                          {
                            game: "",
                            gamerId: "",
                            positions: [],
                          },
                        ])
                      }
                    >
                      {field.value.length ? "Add another game" : "Add a game"}
                    </Button>
                  </Stack>
                </>
              )}
            ></Controller>
          </Card>
          <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
              Bio
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
              <RHFEditor name="bio" label={"Bio"} />
            </Stack>
          </Card>
          <RHFCheckbox
            name="isPublic"
            label={
              <Typography variant="body1" align="left" sx={{ mt: 3 }}>
                Check this box to make your profile public. When your profile is
                public, it will be visible in search result to other users,
                including coaches and recruiters, increasing your chance of
                being discovered.
              </Typography>
            }
            sx={{}}
          />
          <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={
                !(
                  user.username !== values.username ||
                  user.phone !== values.phone ||
                  user.email !== values.email ||
                  values.photoURL !== (user.avatar || "")
                )
              }
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

export const fetchgamerTags = async (callback) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/gamer-tags`
    );
    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchGames = async (callback) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/games`
    );
    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const GameOptionSelector = ({
  games,
  field,
  index,
  control,
  error,
  positions,
  setPositions,
  noGameId = false,
}) => {
  const options = useMemo(() => {
    return (
      games.find(({ name }) => field.value[index]?.name === name)?.positions ||
      []
    );
  }, [field.value, games, index]);

  const handleChange = (arr) => {
    let tempValues = [...field.value];
    arr.forEach(([key, e]) => {
      tempValues[index] = { ...tempValues[index], [key]: e };
    });
    field.onChange(tempValues);
  };

  const handleRemove = () => {
    const filteredGames = field.value.filter((game, i) => i !== index);
    const list = positions.filter(p => p.game !== field.value[index].name);
    setPositions(list);
    field.onChange(filteredGames);
  };

  const location = useLocation();

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 2,
        columnGap: 2,
        gridTemplateColumns: { xs: "repeat(1, 1fr)" },
      }}
    >
      <RHFSelect
        name={`games[${index}].name`}
        label="Game"
        onChange={(e) => {
          handleChange([
            ["name", e.target.value],
            ["_id", games.find(({ name }) => name === e.target.value)?._id],
          ]);
        }}
        value={field.value[index].name}
      >
        <optgroup>
          <option key={"None"} selected value={""}></option>
          {games.map((game) => (
            <option key={game.name} id={game._id} value={game.name}>
              {game.name}
            </option>
          ))}
        </optgroup>
      </RHFSelect>
      {!noGameId ? (
        <RHFTextField
          name={`games[${index}].gamerId`}
          label="Gamer ID"
          onChange={(e) => handleChange([["gamerId", e.target.value]])}
          value={field.value[index].gamerId}
        />
      ) : ""}
      {(field.value[index].name === 'League of Legends' && location.pathname.startsWith("/dashboard/player")) ? (
        <RHFSelect
          name={`games[${index}].region`}
          label="Region"
          onChange={(e) => handleChange([["region", e.target.value]])}
          value={field.value[index].region}
        >
          <optgroup>
            <option key={"None"} selected value={""}></option>
            {games.length && games.find(game => game.name === 'League of Legends').regions.map((region) => (
              <option key={region} value={region.toLowerCase()}>
                {region}
              </option>
            ))}
          </optgroup>
        </RHFSelect>
      ) : ""}
      <Controller
        name={`games[${index}].positions`}
        control={control}
        render={({ field: positionsField }) => (
          <Autocomplete
            multiple
            limitTags={2}
            {...positionsField}
            value={positions.find(p => p.game === field.value[index].name)?.positions || []}
            options={options}
            getOptionLabel={(option) => option}
            defaultValue={[]}
            renderInput={(params) => {
              return <TextField
                error={!!error?.[index]}
                helperText={error?.[index]?.positions?.message || ""}
                {...params}
                multiline={false}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    minWidth: 0
                  }
                }}
                label="Positions"
                name={`games[${index}].postions`}
              />
            }}
            onChange={(_, data) => {
              positionsField.onChange(data)
              let list = [...positions.filter(position => position.game !== field.value[index].name), { game: field.value[index].name, positions: data }]
              setPositions(list)
            }}
          />
        )}
      />

      <Stack alignContent="center" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          sx={{ width: "50%", maxWidth: 158, maxHeight: 35 }}
          color="error"
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Stack>
    </Box>
  );
};

const LocationCard = ({ city, country, state }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await GetCountries();
      setCountries(countries);
    };
    if (!countries.length) {
      fetchCountries();
    }
  }, [countries.length]);

  useEffect(() => {
    const fetchStates = async () => {
      const countryFound = countries.find(({ name }) => name === country);
      if (countryFound) {
        const states = await GetState(countryFound.id);
        setStates(states);
      }
    };
    if (country) {
      fetchStates();
    }
  }, [country, countries]);

  useEffect(() => {
    const fetchCities = async () => {
      const countryFound = countries.find(({ name }) => name === country);
      const stateFound = states.find(({ name }) => name === state);
      if (stateFound && countryFound) {
        const cities = await GetCity(countryFound.id, stateFound.id);
        setCities(cities);
      }
    };
    if (country && state) {
      fetchCities();
    }
  }, [country, state, states, countries]);

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "500", mb: 2.5, ml: 1 }}
      >
        Location
      </Typography>
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
        }}
      >
        <RHFSelect name={`country`} label="Country">
          <optgroup>
            <option key={"None"} value={""}></option>
            {countries.map((country) => (
              <option key={country.id} value={country.name}>
                {country.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>

        <RHFSelect
          name={`state`}
          label={country === "United States" ? "State" : "Region"}
        >
          <optgroup>
            <option key={"None"} value={""}></option>
            {states.map((state) => (
              <option key={state.id} value={state.name}>
                {state.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>
        <RHFSelect name={`city`} label="City">
          <optgroup>
            <option key={"None"} value={""}></option>
            {cities.map((cities) => (
              <option key={cities.id} value={cities.name}>
                {cities.name}
              </option>
            ))}
          </optgroup>
        </RHFSelect>
        {country === "United States" && (
          <RHFTextField name="zipcode" label="Zipcode" />
        )}
      </Box>
    </>
  );
};

export const playerTypes = ["Amateur", "High School", "Collegiate", "Semi Pro", "Pro"];
