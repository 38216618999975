/* eslint-disable no-unused-vars */
import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs } from '@mui/material';

import {  useMemo } from 'react';
import VideoGallery from './galleryVideo';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

const Gallery = ({profile, uploads}) => {
    const { currentTab, onChangeTab, setCurrentTab } = useTabs('gallery');
    const { themeStretch } = useSettings();
    const ACCOUNT_TABS = useMemo(() => {
        const tabs = [
            {
                value: 'gallery',
                icon: <Iconify icon={'mingcute:video-fill'} width={20} height={20} />,
                component: <VideoGallery uploads={uploads} />,
            },     
        ]
       return tabs
        // eslint-disable-next-line
    }, [profile, setCurrentTab, uploads]);

    return (
        <Page title="Player Profile">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs sx={{ mt: 4 }}
                    heading="Video Gallery"
                />
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTab}
                    onChange={onChangeTab}
                    sx={{
                        '& .MuiTab-root': {
                          padding: '12px 12px', // Adjust padding for better clickability
                          justifyContent: 'center', // Center the content
                        },
                      }}
                >
                    {ACCOUNT_TABS.map((tab) => (
                        <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                <Box sx={{ mb: 4 }} />

                {ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    )
}

export default Gallery