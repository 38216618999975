import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const Degrees = ({ degrees, control, label, name }) => {

    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                {label}
            </Typography>
            <Stack mt={2}>
                <Controller
                    name={name}
                    control={control}
                    render={({
                        field: degreesOfferedField,
                        fieldState: { error },
                    }) => (
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            limitTags={-1}
                            id="multiple-limit-tags"
                            options={degrees.map(({ name }) => name)}
                            {...degreesOfferedField}
                            getOptionLabel={(option) => option}
                            getOptionKey={option => option}
                            defaultValue={[]}
                            onChange={(_, data) => degreesOfferedField.onChange(data)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    error={!!error}
                                    helperText={error?.message || ""}
                                />
                            )}
                        />
                    )}
                />
            </Stack>
        </>
    )
}

export default Degrees
