/* eslint-disable no-unused-vars */
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreen from "../components/LoadingScreen";
import PlayerProfile from "src/pages/dashboard/PlayerProfile";
import OrganizationProfile from "src/pages/dashboard/OrganizationProfile";
import MyProspects from "src/pages/dashboard/MyProspects";
import PlayersIManage from "src/pages/dashboard/PlayersIManage";
import Search from "src/pages/dashboard/Search";
import PlayerView from "src/pages/dashboard/ManagePlayerView";
import PublicPlayerProfile from "src/pages/dashboard/public/profile";
import PublcOrganizationProfile from "src/pages/dashboard/public/organization/profile";
import PublicTeamView from "src/pages/dashboard/public/team/profile";
import UserPublicProfile from "src/pages/dashboard/public/user/profile";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "verify", element: <VerifyCode /> },
        {
          path: "register-invite",
          element: (
            <GuestGuard>
              <RegisterViaInvite />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: "terms-of-service", element: <TermsAndService /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        {
          path: "public",
          children: [
            {
              path: "player/:id", element: <PublicPlayerProfile />
            },
            {
              path: "organization/:id", element: <PublcOrganizationProfile/>
            },
            {
              path: "team/:id", element: <PublicTeamView/>
            },
            {
              path: "user/:id", element: <UserPublicProfile/>
            }
          ]
        },
        {
          path: "player",
          children: [
            {
              element: <PlayerProfile />,
              index: true,
            },
            { path: "team/:id", element: <PlayerProfileTeamView /> },
          ],
        },
        {
          path: "organization",
          children: [{
            children: [
              {
                element: <OrganizationProfile />,
                index: true,
              },
            ],
          },
          { path: "team/:id", element: <ManageTeamView /> }],
        },
        {
          path: "user",
          children: [
            {
              path: ":uid/uploads/:upid",
              element: <UserProfileUploadViewById />,
            },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "teams",
          children: [
            { element: <ManageTeams />, index: true },
            { path: "new", element: <CreateTeamForm /> },
            { path: ":id", element: <ManageTeamView /> },
          ],
        },
        {
          path: "squads",
          children: [
            { element: <MyProspects />, index: true },
            { path: "new", element: <SquadCreateForm /> },
            { path: ":id", element: <ManageSquadView /> },
          ],
        },
        {
          path: "players",
          children: [
            { element: <PlayersIManage />, index: true },
            { path: ":id", element: <PlayerView /> }
          ],

        },
        {
          path: "search",
          children: [
            { element: <Search />, index: true },
          ],

        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const RegisterViaInvite = Loadable(
  lazy(() => import("../pages/auth/RegisterViaInvite"))
);

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

const SquadCreateForm = Loadable(
  lazy(() =>
    import(
      "src/sections/@dashboard/user/account/SquadAccountGeneral/SquadCreateForm"
    )
  )
);

// Manage Teams
const ManageTeams = Loadable(
  lazy(() => import("../pages/dashboard/ManageTeams"))
);
const ManageTeamView = Loadable(
  lazy(() => import("../pages/dashboard/ManageTeamView"))
);
const PlayerProfileTeamView = Loadable(
  lazy(() => import("../pages/dashboard/PlayerProfileTeamView"))
);
const ManageSquadView = Loadable(
  lazy(() => import("../pages/dashboard/ManageSquadView"))
);

const UserProfileUploadViewById = Loadable(
  lazy(() => import("../sections/@dashboard/uploads/UploadViewById"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);

const CreateTeamForm = Loadable(
  lazy(() =>
    import(
      "src/sections/@dashboard/user/account/TeamAccountGeneral/TeamAccountCreateForm"
    )
  )
);


// MAIN
const PrivacyPolicy = Loadable(lazy(() => import("../pages/PrivacyPolicy")));
const TermsAndService = Loadable(
  lazy(() => import("../pages/TermsAndService"))
);
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
