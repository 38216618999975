import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { List, Drawer, Divider } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import { NAVBAR } from "../../../config";
import Scrollbar from "../../../components/Scrollbar";
import { InviteRequestItem, JoinRequestItem } from "./MailSidebarItem";

// ----------------------------------------------------------------------

InviteJoinSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenCompose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function InviteJoinSidebar({
  isOpenSidebar,
  onCloseSidebar,
  requests,
  selectRequest,
  selectedRequest,
}) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "md");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <List disablePadding>
        {requests.map((request) => (
          <>
            {request.type === "Team Invite" && (
              <InviteRequestItem
                invite={request}
                select={() => selectRequest(request)}
                selected={selectedRequest?._id === request._id}
              />
            )}
            {request.type === "Join Request" && (
              <JoinRequestItem
                join={request}
                select={() => selectRequest(request)}
                selected={selectedRequest?._id === request._id}
              />
            )}
            <Divider />
          </>
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          variant="permanent"
          PaperProps={{ sx: { width: 400, position: "relative" } }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: NAVBAR.BASE_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
