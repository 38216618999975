import PropTypes from 'prop-types';
import { Avatar as MUIAvatar } from '@mui/material';
import Avatar from './Avatar'

const images = {
    'Valorant': '/assets/games/valorant.png',
    'League of Legends': '/assets/games/leagueoflegends.png',
    'Fortnite': '/assets/games/fortnite.png',
    'Hearthstone': '/assets/games/hearthstone.png',
    'Overwatch': '/assets/games/overwatch.png',
    'Call Of Duty': '/assets/games/callofduty.png',
    'Rocket League': '/assets/games/rocketleague.png',
}

const GameLogo = ({ name, sx, ...other }) => {
    return (
        images[name] ?
            <MUIAvatar
                src={images[name]}
                alt={name}
                sx={sx}
                {...other}
            />
            :
            <Avatar name={name} />
    )


}

GameLogo.propTypes = {
    game: PropTypes.string,
    sx: PropTypes.object,
};

export default GameLogo;