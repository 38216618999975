/* eslint-disable no-unused-vars */
import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections

import PlayerAccountGeneral from 'src/sections/@dashboard/user/account/PlayerAccountGeneral';
import { useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';
import PlayerCollegeForm from 'src/sections/@dashboard/player/PlayerCollegeForm';

// ----------------------------------------------------------------------

export default function PlayerAccount({ profile, fetching, refetch }) {
    const { themeStretch } = useSettings();
    const { currentTab, onChangeTab } = useTabs('about');
    const { user } = useAuth()
    const ACCOUNT_TABS = useMemo(() => {
        const tabs = [
            {
                value: 'about',
                icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
                component: !fetching ? <PlayerAccountGeneral profile={profile} refetch={refetch} /> : null,
            },

        ]

        if (user.goals.isPlayerLookingForCollegeTeam) {
            tabs.push({
                value: 'college_details',
                icon: <Iconify icon={'mdi:college-outline'} width={20} height={20} />,
                component: !fetching ? <PlayerCollegeForm profile={profile} refetch={refetch} /> : null,
            })
        }

        return tabs

    }, [profile, fetching, user.goals?.isPlayerLookingForCollegeTeam, refetch]);


    return (
        <Page title="Edit Player Profile">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs sx={{ mt: 4 }}
                    heading="Edit My Player Profile"
                />

                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTab}
                    onChange={onChangeTab}
                    sx={{
                        '& .MuiTab-root': {
                            // minWidth: 'auto', // Ensure auto width for the tabs
                            padding: '12px 12px', // Adjust padding for better clickability
                            justifyContent: 'center', // Center the content
                        },
                    }}
                >
                    {ACCOUNT_TABS.map((tab) => (
                        <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                <Box sx={{ mb: 4 }} />

                {ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    );
}
