import orderBy from "lodash/orderBy";
import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Input,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import useAuth from "src/hooks/useAuth";
import axios from "src/utils/axios";
import requestHeaders from "src/utils/restClient";
import Iconify from "src/components/Iconify";
import { SkeletonPostItem } from "src/components/skeleton";
import { TeamCard, TeamsSortNew } from "src/sections/@dashboard/manage";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "Name", label: "Name" },
  { value: "Last Edited", label: "Last Edited" },
];

const applySort = (teams, sortBy) => {
  let parsedTeams = teams.map((team) => ({
    ...team,
    loweredCaseName: team.name.toLowerCase(),
    lastEditedTimeStamp: new Date(team.lastEdited).getTime(),
  }));
  if (sortBy === "Name") {
    return orderBy(parsedTeams, ["loweredCaseName"]);
  }
  if (sortBy === "Last Edited") {
    return orderBy(parsedTeams, ["lastEditedTimeStamp"], ["desc"]);
  }

  return teams;
};

export default function PlayerTeams() {
  const isMountedRef = useIsMountedRef();
  const { user } = useAuth();

  const [teams, setTeams] = useState([]);
  const [filters, setFilters] = useState("Name");
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [sortedTeams, setSortedTeams] = useState(applySort(teams, filters));

  const getTeams = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/teams-member`,
        requestHeaders(localStorage.getItem("accessToken"))
      );

      setTeams(response.data);
      setSortedTeams(applySort(response.data, filters));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, user._id]);

  useEffect(() => {
    getTeams();
  }, [getTeams]);

  useEffect(() => {
    if (!searchVal.trim()) setSortedTeams(applySort(teams, filters));
    else
      setSortedTeams(
        sortedTeams.filter((team) => team.name.includes(searchVal))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const handleChangeSort = (value) => {
    if (value) {
      setFilters(value);
      setSortedTeams(applySort(teams, value));
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Input
          value={searchVal}
          disableUnderline
          onChange={(event) => setSearchVal(event.target.value)}
          placeholder="Search teams..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ width: 20, height: 20, color: "text.disabled" }}
              />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ddd3",
            borderRadius: "8px",
            py: 1,
            px: 2,
            fontSize: "14px",
          }}
        />

        <TeamsSortNew
          query={filters}
          options={SORT_OPTIONS}
          onSort={handleChangeSort}
        />
      </Stack>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        {sortedTeams.length
          ? sortedTeams.map((team, index) =>
              team ? (
                <Grid key={team._id} item xs={12} sm={6} md={4}>
                  <TeamCard team={team} />
                </Grid>
              ) : (
                <SkeletonPostItem key={index} />
              )
            )
          : ""}
      </Grid>

      {isLoading && (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {!teams.length && !isLoading && (
        <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
          You are not a member of any teams.
        </Typography>
      )}
      {teams.length > 0 && !sortedTeams.length && !isLoading && (
        <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
          No teams found.
        </Typography>
      )}
    </Box>
  );
}
