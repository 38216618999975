import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import MyAvatar from "src/components/MyAvatar";
import Image from "src/components/Image";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme, cover }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${cover})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  "&:before": {
    backgroundImage: `url(${cover})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    top: 0,
    zIndex: 9,
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

OrganizationCover.propTypes = {
  myProfile: PropTypes.object,
};

export default function OrganizationCover({
  title = "",
  subtitle = "",
  profile = "",
  isPublicProfile = false,
  email = false,
}) {
  return (
    <RootStyle cover={"/assets/ecruit/profile.webp"}>
      <InfoStyle
        sx={{
          width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyItems: "start",
        }}
      >
        <MyAvatar
          sx={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          profile={profile}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {title && <Typography variant="h4">{title} </Typography>}
          {subtitle && (
            <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
          )}
        </Box>

        {isPublicProfile && email && email !== "" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "row" },
              alignItems: { xs: "center" },
              ml: { xs: 0, md: "auto" },
            }}
          >
            <Button
              sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
              variant="contained"
              href={`mailto:${email}`}
            >
              Contact Organization
            </Button>
          </Box>
        )}
      </InfoStyle>
      <Image
        alt="organization cover"
        src={"/assets/ecruit/profile.webp"}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />
    </RootStyle>
  );
}
