// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container, Stack } from "@mui/material";
import useTabs from "../../hooks/useTabs";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import OrganizationAccount from "./OrganizationAccount";
import { useMemo } from "react";
import MyOrganizationProfile from "src/sections/@dashboard/user/profile/MyOrganizationProfile";
import OrganizationCover from "src/sections/@dashboard/user/profile/MyOrganizationProfile/OrganizationCover";
import Loader from "./Loader";
import useOrganizationProfile from "src/hooks/useOrganizationProfile";
import OrganizationTeams from "./OrganizationTeams";
import OrganizationPlayers from "./OrganizationPlayers";
import Image from "src/components/Image";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function OrganizationProfile() {
  const { themeStretch } = useSettings();
  const { profile, fetching, refetch } = useOrganizationProfile();

  const { currentTab, onChangeTab } = useTabs("details");

  const PROFILE_TABS = useMemo(() => {
    return [
      {
        value: "details",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: (
          <MyOrganizationProfile fetching={fetching} organization={profile} />
        ),
      },
      {
        value: "Our Teams",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-regular"}
            width={20}
            height={20}
          />
        ),
        component: !fetching ? (
          <OrganizationTeams organization={profile} />
        ) : null,
      },
      {
        value: "Our Players",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-filled"}
            width={20}
            height={20}
          />
        ),
        component: !fetching ? (
          <OrganizationPlayers organization={profile} />
        ) : null,
      },
      {
        value: "Edit",
        icon: <Iconify icon={"flowbite:edit-solid"} width={20} height={20} />,
        component: !fetching ? (
          <OrganizationAccount organization={profile} refetch={refetch} />
        ) : null,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, fetching]);

  return (
    <Page title="Organization Profile">
      <Loader loading={fetching}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Stack direction="row">
            <Image
              alt="org animal"
              src={"/assets/ecruit/org-profile.png"}
              sx={{ width: 80, height: 80, mr: 1, mt: 3 }}
            />
            <HeaderBreadcrumbs
              sx={{ mt: 5, mb: 0 }}
              heading="Organization Profile"
              links={[]}
            />
          </Stack>

          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <OrganizationCover
              profile="organizationProfileImage"
              title={profile?.name}
              subtitle="College"
            />
            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
                sx={{
                  maxWidth: "88%",
                  "& .MuiTab-root": {
                    padding: "12px 12px", // Adjust padding for better clickability
                   justifyContent: "center", // Center the content
                 },
               }}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}
