
// @mui
import { Card, Typography, Stack, Link, Tooltip, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
// components
import Iconify from "src/components/Iconify";
import axios from "src/utils/axios";
import DeleteDialog from "../../DeleteDialog";
import AddToSquadDialog from "src/components/dialog/AddToSquadDialog";
import uuidv4 from "src/utils/uuidv4";


export const PlayerSquadsInfo = ({ squads, player, refetch, squadsList }) => {
    const [open, setOpen] = useState(false)
    const [selectedSquad, setSelectedSquad] = useState(null);
    const [isDeleteModalOpen, setIsDeleteDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const openDeleteModal = (squad) => {
        setSelectedSquad(squad);
        setIsDeleteDialogOpen(true)
    }


    const filteredSquads = useMemo(() => {
        const squadsMap = {};
        squads.forEach(squad => squadsMap[squad.id] = true);
        return squadsList.filter(squad => !squadsMap[squad._id])
        // eslint-disable-next-line 
    }, [squadsList, squads])


    const handleDelete = async () => {
        try {
            await axios.delete(`/squads/${selectedSquad.id}/players/${player._id}`);
            refetch()
            enqueueSnackbar("Player removed from squad successfully.", {
                autoHideDuration: 4000,
                variant: "success",
            });
            
        }
        catch (err) {
            enqueueSnackbar("Error removing player from squad.", {
                autoHideDuration: 4000,
                variant: "error",
            });
        }
        finally {
            // Close the modal regardless of success or error
            setIsDeleteDialogOpen(false);
        }
    }
    return (
        <Card>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ pt: 1, px: 3 }}>
                <Typography variant="subtitle1" fontWeight={"bold"}>Player Squads</Typography>
                <Tooltip title="Add to Squad">
                    <IconButton onClick={() => setOpen(true)}>
                        <Iconify icon={"flat-color-icons:plus"} color="#44931C" width={20} height={20} />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Stack spacing={2} sx={{ p: 3, pt: 1 }}>
                {squads.map(squad => <PlayerSquadInfo squad={squad} key={uuidv4()} openDeleteModal={openDeleteModal} />)}
            </Stack>
            <AddToSquadDialog open={open} handleClose={() => setOpen(false)} squadsList={filteredSquads}  player={player} refetch={refetch}/>
            {selectedSquad &&
                <DeleteDialog
                    title={`Are you sure you want to remove this player from the ${selectedSquad.name} squad?`}
                    open={isDeleteModalOpen}
                    handleConfirm={handleDelete}
                    handleClose={() => setIsDeleteDialogOpen(false)}
                />}
        </Card>
    )
}


const PlayerSquadInfo = ({ squad, openDeleteModal }) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
            <Link
                component="a"
                variant="subtitle2"
                key={squad.id}
                color="text.primary"
                noWrap
                href={`/dashboard/squads/${squad.id}`}
            > <Typography variant="subtitle2">{squad.name}</Typography>
            </Link>
            <IconButton
                onClick={() => openDeleteModal(squad)}
            >
                <Iconify icon={"lets-icons:remove-fill"} color="#E36A52" width={20} height={20} />

            </IconButton>

        </Stack>
    )
}
