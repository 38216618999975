import { Box, Card, CardHeader, Stack, Typography } from "@mui/material";
import Markdown from "src/components/Markdown";
import uuidv4 from "src/utils/uuidv4";

const PlayerDetails = ({ squads, teams }) => {
    return (
        <>
            <Card sx={{ pb: 2 }}>
                <CardHeader title="Player Stats" />
            </Card>
            <Card sx={{ pb: 2 }}>
                <CardHeader title="Player Private Notes" />
                <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
                    {squads.map(squad => <Box key={uuidv4()}>
                        <Typography variant="subtitle1">{`Squad: ${squad.name}`}</Typography>
                        <Stack sx={{ ml: 2 }}>
                        <Markdown>{squad.playerNotes || "No notes"}</Markdown>
                        </Stack>
                    </Box>)}
                    {teams.map(team => <Box key={uuidv4()}>
                        <Typography variant="subtitle1">{`Team: ${team.name}`}</Typography>
                        <Stack sx={{ ml: 2 }}>
                        <Markdown>{team.privateNotes || "No notes"}</Markdown>
                        </Stack>
                    </Box>)}
                </Stack>
            </Card>
        </>
    )
}

export default PlayerDetails