// @mui
import {
  Container,
  Typography,
  Stack,
  CircularProgress,
  Grid,
  Card,
  Box,
  Chip,
  Link,
} from "@mui/material";
// routes
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// sections
import { useOrgTeams } from "src/hooks/useTeams";
import Avatar from "src/components/Avatar";
import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

export default function OrganizationPlayers({ organization }) {
  const { themeStretch } = useSettings();
  const { teams, isLoading } = useOrgTeams(organization._id);

  return (
    <Page title="Our Players">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          moreLink={null}
          sx={{ mt: 4, mb: 1 }}
          heading="Our Players"
        />

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {teams?.uniquePlayers?.length
            ? teams?.uniquePlayers?.map((player) => (
                <Grid key={player._id} item xs={12} sm={6} md={4}>
                  <Player player={player} />
                </Grid>
              ))
            : ""}
        </Grid>

        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!teams?.uniquePlayers?.length && !isLoading && (
          <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
            This organization does not have any players.
          </Typography>
        )}
      </Container>
    </Page>
  );
}

export const Player = ({ player }) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        borderRadius: "16px",
        color: "black",
        aspectRatio: "1/1",
        textAlign: "center",
        px: 3,
        position: "relative",
      }}
    >
      {player?.featured && (
        <Iconify
          icon="ic:baseline-star"
          sx={{
            zIndex: 10,
            top: 12,
            right: 12,
            position: "absolute",
            width: { xs: 24, md: 28 },
            height: { xs: 24, md: 28 },
            color: "gold",
          }}
        />
      )}

      <Avatar
        alt={player.userId.username}
        src={player.avatarUrl}
        sx={{ width: 80, height: 80 }}
        name={player.userId.username}
      />

      {player.isPublic && (
        <>
          <Link
            to={PATH_DASHBOARD.public.player(player._id)}
            color="inherit"
            component={RouterLink}
          >
            <Typography sx={{ color: "white" }} variant="h5">
              {player.userId.username}
            </Typography>
          </Link>
        </>
      )}

      {!player.isPublic && (
        <>
          <Typography sx={{ color: "white" }} variant="h5">
            {player.userId.username}
          </Typography>
        </>
      )}

      {!player.isPublic && (
        <Chip key={player.userId} label={"Private"} color="primary" />
      )}

      <Box
        sx={{
          mt: 1.8,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "12px",
          justifyContent: "center",
          height: "72px",
          overflow: "hidden",
        }}
      >
        {player &&
          player?.games?.map((game) => (
            <Chip
              sx={{
                fontSize: "14px",
                backgroundColor: "#4c13ac",
                color: "white",
                fontWeight: 500,
              }}
              label={[
                game.name,
                game.positions.length
                  ? game.positions.slice(0, 2).join(", ")
                  : null,
              ]
                .filter((val) => val)
                .join(" - ")}
            ></Chip>
          ))}
      </Box>
    </Card>
  );
};
