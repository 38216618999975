import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Chip, Stack, Typography, Link } from "@mui/material";
import Avatar from "../../../components/Avatar";
import { formatDate } from "fullcalendar";
import { useMemo } from "react";
import Iconify from "src/components/Iconify";
import { useLocation } from "react-router";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: 84,
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  justifyContent: "space-between",
}));

// ----------------------------------------------------------------------

MailDetailsToolbar.propTypes = {
  mail: PropTypes.object,
};

export default function MailDetailsToolbar({ mail, ...other }) {
  const statusColor = useMemo(() => {
    switch (mail.status) {
      case "Pending":
        return "warning";
      case "Accepted":
        return "success";
      case "Rejected":
        return "error";
      default:
        return "default";
    }
  }, [mail.status]);

  const location = useLocation();

  return (
    <>
      <RootStyle {...other}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt={mail.name}
            src={mail.avatar || ""}
            name={mail.name}
          ></Avatar>

          <Box sx={{ ml: 2 }}>
            {mail.public ? (
              <Link href={`/dashboard/public/player/${mail.playerId}`}>
                <Typography display="inline" variant="subtitle2">
                  {mail.name}
                </Typography>
              </Link>
            ) : (
              <Typography display="inline" variant="subtitle2">
                {mail.name}
              </Typography>
            )}
          </Box>
        </Box>
        <Box flexDirection={"row"} alignItems={"center"}>
          <Chip
            color={statusColor}
            size="small"
            label={mail.status}
            sx={{ mr: 2 }}
          ></Chip>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {mail.createdAt && formatDate(mail.createdAt)}
          </Typography>
        </Box>
      </RootStyle>
      {location.pathname.startsWith("/dashboard/player") &&
        mail.type === "invite" && (
          <Stack sx={{ px: 3, pb: 2 }} direction="row">
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <strong>From:</strong> {mail.teamName}
            </Typography>
            {mail.teamPublic && (
              <Link href={`/dashboard/public/team/${mail.teamId}`}>
                <Iconify
                  sx={{ ml: 1, mt: 0.25 }}
                  width={20}
                  height={20}
                  icon={"pepicons-pop:open"}
                />
              </Link>
            )}
          </Stack>
        )}
      {location.pathname.startsWith("/dashboard/player") &&
        mail.type === "join" && (
          <Stack sx={{ px: 3, pb: 2 }} direction="row">
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <strong>Requested Team:</strong> {mail.teamName}
            </Typography>
            {mail.teamPublic && (
              <Link href={`/dashboard/public/team/${mail.teamId}`}>
                <Iconify
                  sx={{ ml: 1, mt: 0.25 }}
                  width={20}
                  height={20}
                  icon={"pepicons-pop:open"}
                />
              </Link>
            )}
          </Stack>
        )}
    </>
  );
}
