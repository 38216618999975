import jwtDecode from "jwt-decode";
import axios from "./axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;

    if (!isValidToken(accessToken)) {
      // redirect to homepage
      localStorage.removeItem("accessToken");

      // Remove all other local storage 
      localStorage.removeItem("profileImage");
      localStorage.removeItem("_id");
      localStorage.removeItem("permissions");
      localStorage.removeItem("roleName");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("username");
      localStorage.removeItem("organizationProfileImage");
      localStorage.removeItem("playerProfileImage");
      localStorage.removeItem("fullName");
      localStorage.removeItem("goals");


      delete axios.defaults.headers.common.Authorization;
      window.location.href = "/";
    }
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
    window.location.href = "/";
  }
};

export { isValidToken, setSession };
