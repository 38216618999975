// @mui
import {
  Container,
  Typography,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
// routes
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// sections
import { useOrgTeams } from "src/hooks/useTeams";
import { TeamCard } from "src/sections/@dashboard/manage";
import { SkeletonPostItem } from "src/components/skeleton";

// ----------------------------------------------------------------------

export default function OrganizationTeams({ organization }) {
  const { themeStretch } = useSettings();
  const { teams, isLoading } = useOrgTeams(organization._id);

  return (
    <Page title="Our Teams">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs sx={{ mt: 4, mb: 1 }} heading="Our Teams" />

        <Grid container spacing={3}>
          {teams?.featuredTeams?.length
            ? teams?.featuredTeams?.map((team, index) =>
                team ? (
                  <Grid key={team._id} item xs={12} sm={6} md={4}>
                    <TeamCard team={team} />
                  </Grid>
                ) : (
                  <SkeletonPostItem key={index} />
                )
              )
            : ""}
        </Grid>

        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!teams?.featuredTeams?.length && !isLoading && (
          <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
            This organization does not have any featured teams.
          </Typography>
        )}
      </Container>
    </Page>
  );
}
