import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Card,
  CardContent,
  Stack,
  Chip,
  Typography,
  AvatarGroup,
} from "@mui/material";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/Iconify";
import TextMaxLine from "src/components/TextMaxLine";
import Avatar from "src/components/Avatar";
import uuidv4 from "src/utils/uuidv4";

// ----------------------------------------------------------------------

TeamCard.propTypes = {
  team: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function TeamCard({ team, isPublicProfile = false }) {
  return (
    <Card sx={{ p: 1, position: "relative" }}>
      <Avatar
        alt={team?.name}
        src={team.avatarUrl}
        name={team?.name}
        sx={{
          zIndex: 9,
          top: 24,
          left: 20,
          width: { xs: 45, md: 55 },
          height: { xs: 45, md: 55 },
          position: "absolute",
        }}
      />

      {team?.featuredOnProfile && (
        <Iconify
          icon="ic:baseline-star"
          sx={{
            zIndex: 10,
            top: 12,
            right: 12,
            position: "absolute",
            width: { xs: 24, md: 28 },
            height: { xs: 24, md: 28 },
            color: "gold",
          }}
        />
      )}

      <PostContent
        key={uuidv4()}
        isPublicProfile={isPublicProfile}
        team={team}
        title={team.name}
        players={team.players}
        games={team.gamesPlayedByTeam}
        id={team._id}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

PostContent.propTypes = {
  team: PropTypes.object,
  title: PropTypes.string,
  players: PropTypes.array,
  games: PropTypes.array,
  id: PropTypes.string,
};

export function PostContent({ title, players, games, id, team }) {
  let location = useLocation();

  function getLink() {
    if (location.pathname.includes("/dashboard/player")) {
      // return PATH_DASHBOARD.profile.player.viewTeam(id);
      return PATH_DASHBOARD.public.team(id);
    }
    if (location.pathname.includes("/dashboard/organization")) {
      // return PATH_DASHBOARD.profile.organization.viewTeam(id);
      return PATH_DASHBOARD.public.team(id);
    }
    if (location.pathname.includes("/dashboard/public/organization")) {
      return PATH_DASHBOARD.public.team(id);
    }
    if (location.pathname.includes("/dashboard/public/player")) {
      return PATH_DASHBOARD.public.team(id);
    } else {
      return PATH_DASHBOARD.teams.view(id); // what is this case used for???
    }
  }
  const linkTo = getLink();
  return (
    <CardContent
      key={uuidv4()}
      sx={{
        pt: 12,
        width: 1,
        px: 2,
        height: "380px",
        position: "relative",
      }}
    >
      {(team.isPublic || location.pathname.includes("/dashboard/teams")) && (
        <Link
          to={linkTo}
          // target="_blank"
          color="inherit"
          component={RouterLink}
        >
          <TextMaxLine variant="h6" line={1} persistent>
            {title || "No Title"}
          </TextMaxLine>
        </Link>
      )}

      {!team.isPublic && !location.pathname.includes("/dashboard/teams") && (
        <TextMaxLine variant="h6" line={1} persistent>
          {title || "No Title"}
        </TextMaxLine>
      )}

      {!!team.linkedOrganization && team.linkedOrganization.name ? (
        <Typography
          sx={{
            color: "text.disabled",
            opacity: 0.9,
            fontSize: "14px",
            mt: "5px",
          }}
        >
          {team.linkedOrganization.name}
        </Typography>
      ) : (
        ""
      )}

      <Box
        component="div"
        key={uuidv4()}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          fontSize: "13px",
          color: "white",
          opacity: "0.7",
          mt: "6px",
        }}
      >
        <Iconify icon="ic:baseline-people" sx={{ width: 16 }} />
        {players.length}&nbsp;players
      </Box>

      <Box
        key={uuidv4()}
        sx={{
          mt: 1.8,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "12px",
        }}
      >
        {games.map((game) => (
          <Chip key={uuidv4()} label={game.name}></Chip>
        ))}
      </Box>

      {!team.isPublic && (
        <Chip
          key={uuidv4()}
          sx={{ mt: 1 }}
          color="primary"
          label={"Private"}
        ></Chip>
      )}

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "10px",
          color: "common.white",
          height: "30px",
        }}
      >
        <AvatarGroup max={5}>
          {players.slice(0, 5).map((player, i) => (
            <Avatar
              key={uuidv4()}
              src={player.avatarUrl}
              name={player.userId.username}
              sx={{
                width: "30px",
                height: "30px",
              }}
            />
          ))}
        </AvatarGroup>
      </Stack>
    </CardContent>
  );
}
