import { useContext } from 'react';
//
import { AuthContext } from '../contexts/JWTContext';
//
import { AUTH_REQUIRED_ROUTES, PATH_AUTH } from "src/routes/paths";


// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('Auth context must be use inside AuthProvider');
  const isAuthRequired = AUTH_REQUIRED_ROUTES.some((route) => window.location.pathname.startsWith(route));
  if (isAuthRequired && context?.isInitialized && !context?.isAuthenticated) window.location.href = PATH_AUTH.login;
  else return context;
};

export default useAuth;
