import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  IconButton,
  Typography,
  CardContent,
  MenuItem,
  Divider,
  Container,
  Button,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import LightboxModal from "../../../../components/LightboxModal";
import MenuPopover from "src/components/MenuPopover";
import requestHeaders from "src/utils/restClient";
import axios from "src/utils/axios";
import { MotionContainer, varBounce } from "src/components/animate";
import { m } from "framer-motion";
import UploadMediaContent from "../../uploads/UploadMediaContent";
import UploadDetailsSummary from "../../uploads/UploadDetailsSummary";
import { useLocation } from "react-router";
import useAuth from "src/hooks/useAuth";

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  top: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  justifyContent: "space-between",
  color: theme.palette.common.white,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

PlayerVideoGallery.propTypes = {
  gallery: PropTypes.array
};

export default function PlayerVideoGallery({
  gallery = [],
  uploadVideo,
  uploads,
  loading,
  error,
}) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const imagesLightbox = gallery.map((img) => img.imageUrl);

  const { pathname } = useLocation();

  return (
    <Box sx={{ mt: 5 }}>
      {!error &&
        !loading &&
        uploads !== null &&
        uploads !== undefined &&
        uploads.length === 0 && (
          <>
            <Container sx={{ mt: -30 }} component={MotionContainer}>
              <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
                <m.div variants={varBounce().in}>
                  <Typography variant="h3" paragraph>
                    Oops!
                  </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                  <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                    Sorry, we couldn’t find any gameplay uploads associated with
                    this profile.
                  </Typography>
                </m.div>

                {!pathname.includes("/public") && (
                  <Button
                    to="/dashboard/uploads/new"
                    size="large"
                    variant="contained"
                    onClick={() => uploadVideo()}
                  >
                    Create a New Upload
                  </Button>
                )}
              </ContentStyle>
            </Container>
          </>
        )}

      {error && !loading && (
        <>
          <Container sx={{ mt: -30 }} component={MotionContainer}>
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  Oops!
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                  We encountered an error fetching gameplay uploads for this
                  profile. Please try again.
                </Typography>
              </m.div>

              <Button
                size="large"
                variant="contained"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </ContentStyle>
          </Container>
        </>
      )}

      {uploads !== null &&
        uploads !== undefined &&
        uploads.length > 0 &&
        !loading && (
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: "grid",
                gap: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                },
              }}
            >
              {uploads.map((upload) => (
                <GalleryItem image={upload} />
              ))}
            </Box>

            <LightboxModal
              images={imagesLightbox}
              mainSrc={imagesLightbox[selectedImage]}
              photoIndex={selectedImage}
              setPhotoIndex={setSelectedImage}
              isOpen={openLightbox}
              onCloseRequest={() => setOpenLightbox(false)}
            />
          </Card>
        )}
      {loading && (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}

GalleryItem.propTypes = {
  image: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

function GalleryItem({ image }) {
    const { user } = useAuth()
    const userId = user._id

  const [open, setOpen] = useState(null);
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleDelete = async (uploadId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}`,
        {},
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFeature = async (uploadId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}/featured`,
        { featured: true },
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnfeature = async (uploadId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}/featured`,
        { featured: false },
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ position: "relative" }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={7}>
          <UploadMediaContent upload={image._doc} videoUrl={image.videoUrl} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <UploadDetailsSummary videoUrl={image.videoUrl} upload={image._doc} />
        </Grid>
      </Grid>
      <CaptionStyle>
        <div></div>
        <Stack direction={"row"} alignItems={"center"}>
          {image._doc.featured && (
            <Iconify icon={"twemoji:star"} width={20} height={20} />
          )}
          <IconButton onClick={handleOpen} color="inherit">
            <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
          </IconButton>
        </Stack>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          }}
        >
          <MenuItem
            sx={{ m: 1 }}
            onClick={
              image._doc?.featured === true
                ? () => handleUnfeature(image._doc._id)
                : () => handleFeature(image._doc._id)
            }
          >
            {image._doc.featured === true ? "Unfeature" : "Feature"}
          </MenuItem>
          <Divider />
          <MenuItem sx={{ m: 1 }} onClick={() => handleDelete(image._doc._id)}>
            Delete
          </MenuItem>
        </MenuPopover>
      </CaptionStyle>
    </Card>
  );
}
