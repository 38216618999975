import { useSnackbar } from "notistack";
import { useCallback, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Card, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../../../hooks/useAuth";
import { fData } from "../../../../../utils/formatNumber";
import {
  FormProvider,
  RHFCheckbox,
  RHFUploadAvatar,
} from "src/components/hook-form";
import axios from "../../../../../utils/axios";
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
import requestHeaders from "src/utils/restClient";
import "react-country-state-city/dist/react-country-state-city.css";
import { Validation } from "src/utils/validations";
import { OrganisationService } from "src/services/organisation";
import useOfferedDegrees from "src/hooks/useOfferedDegrees";
import useGames from "src/hooks/useGames";
import Info from "./Info";
import Socials from "./Socials";
import Degrees from "./Degrees";
import Description from "./Description";
import GamesPlayed from "./GamesPlayed";
import LocationAndContactInfo from "./LocationAndContactInfo";
import PrerollCard from "./PrerollCard";
import AcademicCard from "./AcademicCard";
import TutionCard from "./TutionCard";
import ScholarshipCard from "./ScholarshipCard";
import EsportsCard from "./EsportsCard";

export default function OrganizationAccountGeneral({ organization, refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const { offeredDegrees } = useOfferedDegrees();
  const { games } = useGames();
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const isMountedRef = useIsMountedRef();
  const { user } = useAuth();

  const profilePhotoExists = useMemo(() => {
    return !!organization?.avatar;
  }, [organization?.avatar]);

  const defaultValues = useMemo(
    () => processOrganization(organization),
    [organization]
  );


  const handleUploadVideo = async () => {
    let url = values.youtubeLink;
    let videoUploaded = false;
    if (values.videoUpload) {
      if (values.videoFile?.name) {
        const video = await OrganisationService.uploadFileToOrganization(
          user._id,
          organization._id,
          values.videoFile
        );
        if (video) {
          url = video.fileLocation;
          videoUploaded = true;
        } else {
          throw new Error("Failed to upload video");
        }
      } else {
        url = values.video;
      }
    }
    if (
      values.video &&
      !values.video?.includes("https://www.youtube.com") &&
      videoUploaded
    ) {
      await OrganisationService.deleteVideoFile(
        user._id,
        organization._id,
        values.video
      );
    }
    return url;
  };

  const methods = useForm({
    resolver: yupResolver(Validation.OrganizationAccountValidationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onError = () => {
    enqueueSnackbar("You have some errors in your form. Please review the highlighted fields above.", {
      autoHideDuration: 4000,
      variant: "error",
    });
  }

  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    control,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();
  console.log(errors)

  //SINGLE FILE UPLOAD (AVATAR)
  // eslint-disable-next-line no-unused-vars

  async function handleUpload(uid, fileType) {
    let formData = new FormData();
    formData.append("files", fileType);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/users/${uid}/organizations/${organization._id}/uploadAvatarFile`,
        data: formData,
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      return res.data;
    } catch (error) {
      return;
    }
  }

  const [profileImageUpdated, setProfileImageUpdated] = useState(false);
  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      //handle the esport upload
      const esportsTitles = await uploadEsportImages(
        user._id,
        organization._id,
        values.esportsTitles
      );
      let video = await handleUploadVideo();
      const avatar = await uploadAvatar();
      await OrganisationService.patchOrganizationProfileById(
        user._id,
        organization._id,
        {
          ...values,
          avatar: avatar,
          video: video,
          gamesPlayedByTeam: values.gamesPlayedByTeam.map(({ _id }) => _id),
          esportsTitles,
        }
      );
      await OrganisationService.patchOrganizationPublicSettingsById(
        user._id,
        organization._id,
        values.isPublic
      );

      localStorage.removeItem("organizationProfileImage");
      localStorage.setItem("organizationProfileImage", avatar);
      enqueueSnackbar("Changes have been saved.", {
        autoHideDuration: 2000,
        variant: "success",
      });
      refetch()
    } catch (error) {
      setError("afterSubmit", { ...error, message: error.message }); //Show an alert box if state errors
    }
  };

  const uploadAvatar = async () => {
    let avatar = values.avatar;
    if (values.photoURL !== null) {
      //Upload organization image if it was updated
      if (profileImageUpdated && values.photoURL.path !== undefined) {
        const { signedFileUrl, fileLocation } = await handleUpload(
          user._id,
          values.photoURL
        );
        avatar = fileLocation;
        localStorage.setItem("organisationProfileImage", signedFileUrl);
      }
    } else {
      avatar = null;
    }
    if (profilePhotoExists === true && profileImageUpdated) {
      //need to remove the old image file from S3bucket
      await removeOldOrganizationPhoto();
    }
    return avatar;
  };

  const removeOldOrganizationPhoto = useCallback(async () => {
    const postData = {
      bucketKey: organization?.avatar,
    };
    if (
      organization?.avatar !== "undefined" &&
      organization?.avatar !== undefined &&
      organization?.avatar !== "" &&
      organization?.avatar !== null
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/organizations/${organization._id}/deleteAvatarFile`,
          postData,
          appHeaders
        );
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, appHeaders]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "photoURL",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setProfileImageUpdated(true);
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: "center" }}>
            <RHFUploadAvatar
              name="photoURL"
              accept="image/*"
              maxSize={1048576}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: "auto",
                    display: "block",
                    textAlign: "center",
                    color: "text.secondary",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp
                  <br /> max size of {fData(1048576)}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Info
          announcement={` Use this section to announce or highlight important
        information about your organization. Share key updates, such
        as recruitment opportunities, upcoming events, or special
        programs.`}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, mt: 3 }}>
            <Socials />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, mt: 3 }}>
            <Degrees
              degrees={offeredDegrees}
              control={control}
              label={"Degrees Offered"}
              name={"degreesOffered"}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, mt: 3 }}>
            <Description
              title={"Description"}
              subtitle={`Provide a detailed description of your organization. Include
                            information about who you are, your mission, the types of players
                            or talent you are looking for, and any other relevant details that
                            can help others understand your organization better.`}
              name="schoolDescription"
            />
          </Card>
          <Card sx={{ p: 3, mt: 3 }}>
            <GamesPlayed games={games} control={control} name="gamesPlayedByTeam" />
          </Card>
          <Card sx={{ p: 3, mt: 3 }}>
            <LocationAndContactInfo
              values={values}
              control={control}
              errors={errors}
            />
          </Card>
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item>
            <PrerollCard values={values} control={control} />
            <AcademicCard />
            <TutionCard />
            <ScholarshipCard values={values} control={control} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12}>
            <EsportsCard cntrol={control} setValue={setValue} values={values} />
          </Grid>
        </Grid>
        <Stack sx={{ padding: 2 }}>
          <RHFCheckbox
            name="isPublic"
            label={
              <Typography variant="body2" align="left" sx={{ mt: 2 }}>
                By checking this box, your Organization Profile will be public
                and searchable by players. This increases visibility and allows
                potential recruits to easily find and connect with your team,
                enhancing your chances of attracting top talent.
              </Typography>
            }
            sx={{}}
          />

          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent={"space-between"}
          >
            <RHFCheckbox
              name="officialRepresentativeCheckbox"
              label={
                <Typography
                  variant="body2"
                  align="left"
                  sx={{
                    ...(errors.officialRepresentativeCheckbox?.message && {
                      color: "error.light",
                    }),
                  }}
                >
                  I am an official representative of this school and understand
                  that my affiliation will be verified via my email address.{" "}
                </Typography>
              }
              sx={{}}
            />

          </Stack>
        </Stack>
        <Stack sx={{ padding: 2 }}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={
              !(
                user.username !== values.username ||
                user.phone !== values.phone ||
                user.email !== values.email ||
                values.photoURL !== (user.avatar || "")
              )
            }
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </Grid>
    </FormProvider>
  );
}

function processOrganization(organization) {
  const videoUpload =
    !organization?.video?.includes("https://www.youtube.com") ||
      organization?.video === ""
      ? true
      : false;
  return {
    name: organization?.name || "",
    photoURL: organization?.avatarUrl || "",
    avatar: organization?.avatar || "",
    announcement: organization?.announcement || "",
    locationCountryCode: organization?.locationCountryCode || "",
    locationState: organization?.locationState || "",
    locationCity: organization?.locationCity || "",
    locationZipCode: organization?.locationZipCode || "",
    schoolDescription: organization?.schoolDescription || "",
    email: organization?.email || "",
    phone: organization?.phone || "",
    website: organization?.website || "",
    discord: organization?.discord || "",
    video: organization?.video || "",
    socials: {
      instagram: organization?.socials?.instagram || "",
      twitter: organization?.socials?.twitter || "",
      facebook: organization?.socials?.facebook || "",
      threads: organization?.socials?.threads || "",
    },
    totalNumberOfTeamsInOrganization:
      organization?.totalNumberOfTeamsInOrganization || 0,
    totalNumberOfPlayersInOrganization:
      organization?.totalNumberOfPlayersInOrganization || 0,
    gamesPlayedByTeam: organization?.gamesPlayedByTeam || [],
    academicAdmissionRequirements:
      organization?.academicAdmissionRequirements || "",
    tuitionAmounts: organization?.tuitionAmounts || "",
    scholarshipsOffered: organization?.scholarshipsOffered || false,
    scholarshipsOfferedText: organization?.scholarshipsOffered
      ? organization?.scholarshipsOfferedText || ""
      : "",
    organizationType: "College",
    esportsTitles: organization?.esportsTitles || [],
    degreesOffered: organization?.degreesOffered || [],
    videoUpload: videoUpload,
    videoFile:
      videoUpload && organization?.video
        ? {
          preview: "/assets/videofileuploaded.jpg",
        }
        : null,
    youtubeLink: videoUpload ? "" : organization?.video,
    isPublic:
      organization?.isPublic !== undefined ? organization?.isPublic : true,
    officialRepresentativeCheckbox:
      organization?.officialRepresentativeCheckbox !== undefined
        ? organization?.officialRepresentativeCheckbox
        : false,
  };
}

async function uploadEsportImages(userId, organisationId, esportTitles) {
  return await Promise.all(
    esportTitles.map(async ({ name, logo, logoUrl }) => {
      if (logoUrl && typeof logoUrl !== "string") {
        const { fileLocation } =
          await OrganisationService.uploadFileToOrganization(
            userId,
            organisationId,
            logoUrl
          );
        return {
          name,
          logo: fileLocation,
        };
      }
      return {
        name,
        logo,
      };
    })
  );
}
