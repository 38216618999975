import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  IconButton,
  Typography,
  CardContent,
  MenuItem,
  Divider,
  CardActionArea,
  Container,
  CardMedia,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import cssStyles from "../../../../utils/cssStyles";
import Iconify from "../../../../components/Iconify";
import LightboxModal from "../../../../components/LightboxModal";
import Label from "src/components/Label";
import MenuPopover from "src/components/MenuPopover";
import requestHeaders from "src/utils/restClient";
import axios from "src/utils/axios";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { MotionContainer, varBounce } from "src/components/animate";
import { m } from "framer-motion";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import useAuth from "src/hooks/useAuth";

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  bottom: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  justifyContent: "space-between",
  color: theme.palette.common.white,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

ProfileGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default function ProfileGallery({ gallery }) {
  const { user } = useAuth()
  const userId = user._id
  const [openLightbox, setOpenLightbox] = useState(false);
  const isMountedRef = useIsMountedRef();
  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox = gallery.map((img) => img.imageUrl);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const { pathname } = useLocation();

  const [uploads, setUploads] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUserUploads = useCallback(async () => {
    try {
      //Fetch uploads by this user
      const uploads = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads`,
        {}
      );

      setUploads(uploads.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, userId]);

  useEffect(() => {
    getUserUploads();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Video Gallery
      </Typography>

      {!error &&
        !loading &&
        uploads !== null &&
        uploads !== undefined &&
        uploads.length === 0 && (
          <>
            <Container sx={{ mt: -30 }} component={MotionContainer}>
              <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
                <m.div variants={varBounce().in}>
                  <Typography variant="h3" paragraph>
                    Oops!
                  </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                  <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                    Sorry, we couldn’t find any gameplay uploads associated with
                    this profile.
                  </Typography>
                </m.div>

                {!pathname.includes('/public') &&<Button
                  to="/dashboard/uploads/new"
                  size="large"
                  variant="contained"
                  component={RouterLink}
                >
                  Create a New Upload
                </Button>}
              </ContentStyle>
            </Container>
          </>
        )}

      {error && !loading && (
        <>
          <Container sx={{ mt: -30 }} component={MotionContainer}>
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  Oops!
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                  We encountered an error fetching gameplay uploads for this
                  profile. Please try again.
                </Typography>
              </m.div>

              <Button
                size="large"
                variant="contained"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </ContentStyle>
          </Container>
        </>
      )}

      {uploads !== null &&
        uploads !== undefined &&
        uploads.length > 0 &&
        !loading && (
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: "grid",
                gap: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                },
              }}
            >
              {uploads.map((image) => (
                <GalleryItem
                  key={image._doc._id}
                  image={image}
                  onOpenLightbox={handleOpenLightbox}
                />
              ))}
            </Box>

            <LightboxModal
              images={imagesLightbox}
              mainSrc={imagesLightbox[selectedImage]}
              photoIndex={selectedImage}
              setPhotoIndex={setSelectedImage}
              isOpen={openLightbox}
              onCloseRequest={() => setOpenLightbox(false)}
            />
          </Card>
        )}
      {loading && (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}

GalleryItem.propTypes = {
  image: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

function GalleryItem({ image }) {
  const { user } = useAuth()
  const userId = user._id

  const StyledCardActionArea = styled(CardActionArea)(
    ({ theme }) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`
  );

  const linkTo = PATH_DASHBOARD.user.viewProfileUpload(userId, image._doc._id);
  const theme = useTheme();

  const [open, setOpen] = useState(null);
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleDelete = async (uploadId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}`,
        {},
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFeature = async (uploadId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}/featured`,
        { featured: true },
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnfeature = async (uploadId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads/${uploadId}/featured`,
        { featured: false },
        appHeaders
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ position: "relative" }}>
      <StyledCardActionArea component={RouterLink} to={linkTo}>
      <CardMedia
        component="video"
        sx={{ height: 280 }}
        src={image.videoUrl}
        title={image._doc.customName}
        
      />
      </StyledCardActionArea>
      <CaptionStyle>
        <div>
          <StyledCardActionArea component={RouterLink} to={linkTo}>
            <Typography variant="body2">{image._doc.customName}</Typography>
          </StyledCardActionArea>
          <Stack direction="row">
            <Label sx={{ mt: 1 }}>{image._doc.game}</Label>
            {image._doc.featured === true && (
              <Iconify
                sx={{ mt: 1, ml: 1, color: theme.palette.warning.main }}
                icon={"ic:outline-star"}
                width={22}
                height={22}
              />
            )}
          </Stack>
        </div>
        <IconButton onClick={handleOpen} color="inherit">
          <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
        </IconButton>
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          }}
        >
          <MenuItem
            sx={{ m: 1 }}
            onClick={
              image._doc.featured === true
                ? () => handleUnfeature(image._doc._id)
                : () => handleFeature(image._doc._id)
            }
          >
            {image._doc.featured === true ? "Unfeature" : "Feature"}
          </MenuItem>
          <Divider />
          <MenuItem sx={{ m: 1 }} onClick={() => handleDelete(image._doc._id)}>
            Delete
          </MenuItem>
        </MenuPopover>
      </CaptionStyle>
    </Card>
  );
}
