import PropTypes from "prop-types";
// @mui
import { IconButton } from "@mui/material";
//
import Iconify from "../Iconify";
import MenuPopover from "../MenuPopover";

// ----------------------------------------------------------------------

TableMoreMenuEdit.propTypes = {
  actions: PropTypes.node,
  open: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

export default function TableMoreMenuEdit({
  actions,
  open,
  onClose,
  onOpen,
  width = 160,
}) {
  return (
    <>
      <IconButton onClick={onOpen}>
        <Iconify icon={"lets-icons:edit"} width={20} height={20} color="#ec008b" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
