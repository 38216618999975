import { useParams } from "react-router";
import { useMemo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container, Stack } from "@mui/material";
// hooks
import useTabs from "src/hooks/useTabs";
import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
// sections
import TeamProfile from "src/sections/@dashboard/manage/TeamView";
import ManageMembers from "src/sections/@dashboard/manage/Members";
//utils
import Loader from "../../Loader";
import usePublicTeam from "src/hooks/usePublicTeam";
import PublicTeamCover from "./cover";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Image from "src/components/Image";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PublicTeamView() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { currentTab, onChangeTab } = useTabs("Details");

  const { profile: team, fetching, } = usePublicTeam(id);


  const PROFILE_TABS = useMemo(() => {
    return [
      {
        value: "Details",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: <TeamProfile fetching={fetching} team={team} />,
      },
      {
        value: "Team Members",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-filled"}
            width={20}
            height={20}
          />
        ),
        component: <ManageMembers isPublicProfile={true} fetching={fetching} team={team} refetch={() => { }} />,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, fetching]);

  
  return (
    <Page title="Public Team Profile">
      <Loader loading={fetching}>
        <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row">
          <Image
            alt="team animal"
            src={"/assets/ecruit/team-profile.png"}
            sx={{ width: 80, height: 80, mr: 1, mt: 3 }}
          />
          <HeaderBreadcrumbs
            sx={{ mt: 5, mb: 0 }}
            heading="Public Team Profile"
            links={[
            ]}
          />
        </Stack>
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <PublicTeamCover
              profile={team?.avatarUrl}
              title={team?.name?.trim() || ""}
              subtitle={
                !!team?.linkedOrganization && team?.linkedOrganization.name
                  ? team.linkedOrganization.name
                  : ""
              }
              teamId={team?._id}
              players={team?.players}
              id={id}
            />
            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
                sx={{
                  '& .MuiTab-root': {
                          // minWidth: 'auto', // Ensure auto width for the tabs
                          padding: '12px 12px', // Adjust padding for better clickability
                          justifyContent: 'center', // Center the content
                        },
                }}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}
