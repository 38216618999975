import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  Typography,
  Chip,
  Box,
  Link,
  MenuItem,
} from "@mui/material";
import Avatar from "src/components/Avatar";
import { useState } from "react";
import axios from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import Iconify from "src/components/Iconify";
import { useSnackbar } from "notistack";
import TableMoreMenuEdit from "src/components/table/TableMoreMenuEdit";

PlayersTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};

export default function PlayersTableRow({
  row,
  selected,
  orgProfile,
  refetch,
}) {
  const { userId, avatarUrl, games, teams, _id } = row;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [openMenu, setOpenMenuActions] = useState(null);

  const handleFeatureOnOrgProfile = async (featureFlag = true) => {
    try {
      const organizationId = orgProfile._id;
      await axios.patch(
        `/users/${user._id}/organizations/${organizationId}/players/${_id}/${
          featureFlag ? "feature" : "unfeature"
        }`
      );
      refetch();
      enqueueSnackbar(
        `${featureFlag ? "Featured" : "Unfeatured"} ${userId.username}`,
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        {user.goals.isCollegeTeamRecruitingPlayers && (
          <TableMoreMenuEdit
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            width={280}
            actions={
              <>
                {orgProfile &&
                  !orgProfile.featuredPlayers.includes(row._id) && (
                    <MenuItem
                      onClick={() => {
                        handleFeatureOnOrgProfile();
                        handleCloseMenu();
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      <Iconify
                        icon={"fontisto:star"}
                        sx={{ mr: 1 }}
                        width={18}
                        height={18}
                        
                      />
                      <Typography variant="body2">
                        Feature on Organization Profile
                      </Typography>
                    </MenuItem>
                  )}

                {orgProfile && orgProfile.featuredPlayers.includes(row._id) && (
                  <MenuItem
                    onClick={() => {
                      handleFeatureOnOrgProfile(false);
                      handleCloseMenu();
                    }}
                    sx={{ textAlign: "center" }}
                  >
                    <Iconify
                      icon={"lucide:star-off"}
                      sx={{ mr: 1 }}
                      width={18}
                      height={18}
                    />
                    <Typography variant="body2">
                      Unfeature on Organization Profile
                    </Typography>
                  </MenuItem>
                )}
              </>
            }
          />
        )}
      </TableCell>

      <TableCell>
        <Link
          component="a"
          variant="subtitle2"
          key={_id}
          color="text.primary"
          noWrap
          href={`/dashboard/players/${_id}`}
          // target="_blank"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={userId.username}
              src={avatarUrl}
              sx={{ mr: 2 }}
              name={userId.name}
            ></Avatar>

            <Typography variant="subtitle2" noWrap>
              {userId.username}
            </Typography>
          </Box>
        </Link>
      </TableCell>

      <TableCell align="left">{userId.email}</TableCell>

      <TableCell align="left">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            fontSize: "12px",
            justifyContent: "center",
          }}
        >
          {teams.map((team) => (
            <Chip key={team.name} label={team.name}></Chip>
          ))}
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            fontSize: "12px",
            justifyContent: "center",
          }}
        >
          {games.map((game) => (
            <Chip
              sx={{
                backgroundColor: "#4c13ac",
                color: "white",
                fontWeight: 500,
                height: "fit-content",
                py: 0.5,
              }}
              key={game._id}
              label={
                game.positions.length
                  ? game.name + " - " + game.positions.join(", ")
                  : game.name
              }
            ></Chip>
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
}
