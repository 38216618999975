import {
  Box,
  Button,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Card,
  Divider,
  InputAdornment,
  Stack,
  Input,
  Link,
} from "@mui/material";
import orderBy from "lodash/orderBy";
import { Link as RouterLink } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import Iconify from "src/components/Iconify";
import axios from "src/utils/axios";
import TeamsSortNew from "../TeamsSortNew";
import { FormProvider } from "src/components/hook-form";
import FilterSidebar from "./FilterSidebar";
import { useForm } from "react-hook-form";
import DeleteDialog from "../DeleteDialog";
import TagFiltered from "./TagFiltered";
import ManagePlayer from "./ManagePlayer";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import Avatar from "src/components/Avatar";
import { PATH_DASHBOARD } from "src/routes/paths";

const SORT_OPTIONS = [{ value: "Name", label: "Name" }];

const applySort = (players, sortBy) => {
  if (sortBy === "Name") {
    return orderBy(players, ["name"]);
  }

  return players;
};

export default function ManageMembers({
  fetching,
  team: teamProp,
  refetch,
  isPublicProfile = false,
}) {
  const { user } = useAuth();

  const [team, setTeam] = useState(teamProp);
  const [searchVal, setSearchVal] = useState("");
  const [dropdownFilters, setDropdownFilters] = useState("Name");
  const [openFilter, setOpenFilter] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState({
    open: false,
    func: null,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState({
    open: false,
    player: null,
  });
  const [availableGames, setAvailableGames] = useState([]);
  const [availablePositions, setAvailablePositions] = useState([]);

  const handleChangeSort = (value) => {
    if (value) {
      setDropdownFilters(value);
    }
  };

  const defaultValues = {
    games: "All",
    positions: "All",
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, watch, setValue } = methods;
  const values = watch();

  const isDefault = values.games === "All" && values.positions === "All";

  const handleRemoveGame = () => {
    setValue("games", "All");
  };

  const handleRemovePosition = () => {
    setValue("positions", "All");
  };

  useEffect(() => {
    let games = ["All"];
    team?.playerPositions?.map((player) => {
      player.positionsList.map((position) => {
        games = [...games, position.game.name];
        return position;
      });
      return player;
    });
    games = [...new Set(games)];

    setAvailableGames(games.map((game) => ({ label: game, value: game })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let positions = ["All"];
    team?.playerPositions?.map((player) => {
      player.positionsList.map((position) => {
        positions = [...positions, ...position.positions];
        return position;
      });
      return player;
    });
    positions = [...new Set(positions)];

    setAvailablePositions(
      positions.map((position) => ({ label: position, value: position }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTeam(teamProp);
  }, [teamProp]);

  const sortedPlayers = useMemo(() => {
    const players = team?.players ?? [];
    let sorted = applySort(players, dropdownFilters);
    if (searchVal) {
      sorted = sorted.filter((player) =>
        player.userId.username.includes(searchVal)
      );
    }

    // filted based on game played
    if (values.games && values.games !== "All") {
      let sortedteams = team?.playerPositions?.map((position) =>
        position?.positionsList?.some((pos) => pos.game.name === values.games)
          ? position.playerId
          : null
      );
      sortedteams = sortedteams.filter((team) => team !== null);
      sorted = sorted.filter((player) => sortedteams.includes(player._id));
    }

    // filted based on positions
    if (values.positions && values.positions !== "All") {
      let sortedteams = team?.playerPositions?.map((position) =>
        position?.positionsList?.some((pos) =>
          pos.positions.includes(values.positions)
        )
          ? position.playerId
          : null
      );
      sortedteams = sortedteams.filter((team) => team !== null);
      sorted = sorted.filter((player) => sortedteams.includes(player._id));
    }

    return sorted;
  }, [team, dropdownFilters, searchVal, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    reset(defaultValues);
    handleCloseFilter();
  };

  const handleCloseSidebar = (notes) => {
    setIsDrawerOpen({ open: false, player: null });
  };

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const isPublicView = location.pathname.startsWith("/dashboard/public/team");

  const handleUpdateNotes = async (values) => {
    try {
      if (
        !team.privatePlayerNotes.find(
          (note) => note.playerId === isDrawerOpen.player._id
        )
      ) {
        setTeam((prev) => ({
          ...prev,
          privatePlayerNotes: prev.privatePlayerNotes.concat({
            playerId: isDrawerOpen.player._id,
            notes: values.privateNotes,
          }),
        }));
      } else {
        setTeam((prev) => ({
          ...prev,
          privatePlayerNotes: prev.privatePlayerNotes.map((note) =>
            note.playerId === isDrawerOpen.player._id
              ? { ...note, notes: values.privateNotes }
              : note
          ),
        }));
      }

      await axios.patch(`/users/${user?._id}/teams/${team?._id}/player-notes`, {
        playerId: isDrawerOpen.player._id,
        notes: values.privateNotes,
      });

      await axios.patch(
        `/users/${user?._id}/teams/${team?._id}/player-positions-batch`,
        {
          playerId: isDrawerOpen.player._id,
          positions: values.games.map((game) => ({
            gameId: game._id || game.id,
            newPositions: game.positions,
          })),
        }
      );

      enqueueSnackbar("Player data saved successfully.", {
        autoHideDuration: 4000,
        variant: "success",
      });

      setIsDrawerOpen({ open: false, player: null });
      await refetch();
    } catch (error) {
      console.log(error);

      enqueueSnackbar("Error saving player data.", {
        autoHideDuration: 4000,
        variant: "error",
      });
      await refetch();
    }
  };

  return (
    <>
      <Stack
        mb={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Input
          value={searchVal}
          disableUnderline
          onChange={(event) => setSearchVal(event.target.value)}
          placeholder="Search team members..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ width: 20, height: 20, color: "text.disabled" }}
              />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ddd3",
            borderRadius: "8px",
            py: 1,
            px: 2,
            fontSize: "14px",
          }}
        />

        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <FormProvider methods={methods}>
            <FilterSidebar
              onResetAll={handleResetFilter}
              isOpen={openFilter}
              onOpen={handleOpenFilter}
              onClose={handleCloseFilter}
              games={availableGames}
              positions={availablePositions}
            />
          </FormProvider>

          <TeamsSortNew
            query={dropdownFilters}
            options={SORT_OPTIONS}
            onSort={handleChangeSort}
          />
        </Stack>
      </Stack>

      <Stack sx={{ mb: 3 }}>
        {!isDefault && (
          <>
            <TagFiltered
              filters={values}
              isShowReset={!isDefault && !openFilter}
              onRemoveGame={handleRemoveGame}
              onRemovePosition={handleRemovePosition}
              onResetAll={handleResetFilter}
            />
          </>
        )}
      </Stack>

      {!fetching ? (
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {sortedPlayers.length
            ? sortedPlayers.map((player) => {
                const playerPosition = team.playerPositions
                  ? team.playerPositions.find(
                      (pos) => pos.playerId === player._id
                    )
                  : null;
                return (
                  <Grid key={player._id} item xs={12} sm={6} md={4}>
                    <Player
                      isPublicProfile={isPublicProfile}
                      playerPositions={playerPosition}
                      player={player}
                      teamId={team._id}
                      setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                      setIsDrawerOpen={setIsDrawerOpen}
                    />
                  </Grid>
                );
              })
            : ""}
        </Grid>
      ) : (
        ""
      )}

      {!fetching && sortedPlayers.length === 0 && (
        <Stack sx={{ textAlign: "center" }}>
          <Typography variant="subtitle1">
            This team does not have any members.
          </Typography>
        </Stack>
      )}

      <DeleteDialog
        open={isDeleteDialogOpen.open}
        handleClose={() =>
          setIsDeleteDialogOpen((open) => ({ ...open, open: false }))
        }
        handleConfirm={isDeleteDialogOpen.func}
        title="Are you sure you want to remove this player from the team?"
      />

      {!isPublicView && (
        <ManagePlayer
          defaultnotes={team.privatePlayerNotes}
          isOpen={isDrawerOpen.open}
          player={isDrawerOpen.player}
          playerPositions={team.playerPositions.find(
            (pos) => pos.playerId === isDrawerOpen?.player?._id
          )}
          onClose={handleCloseSidebar}
          onSave={handleUpdateNotes}
        />
      )}
    </>
  );
}

export const Player = ({
  player,
  playerPositions,
  teamId,
  setIsDeleteDialogOpen,
  setIsDrawerOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const location = useLocation();

  const isTeamManagedByUser = location.pathname.startsWith("/dashboard/teams");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemovePlayer = async () => {
    try {
      await axios.delete(`/teams/${teamId}/players/${player._id}`);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenPopup = () => {
    setIsDeleteDialogOpen({ open: true, func: handleRemovePlayer });
  };

  const handleOpenSidebar = () => {
    handleClose();
    setIsDrawerOpen({ open: true, player });
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        borderRadius: "16px",
        color: "black",
        aspectRatio: "1/1",
        textAlign: "center",
        px: 3,
        position: "relative",
      }}
    >
      <Avatar
        alt={player.userId.username}
        src={player.avatarUrl}
        name={player.userId.username}
        sx={{ width: 80, height: 80 }}
      />

      {player.isPublic && (
        <>
          <Link
            to={PATH_DASHBOARD.public.player(player._id)}
            color="inherit"
            component={RouterLink}
          >
            <Typography sx={{ color: "white" }} variant="h5">
              {player.userId.username}
            </Typography>
          </Link>
        </>
      )}

      {!player.isPublic && (
        <>
          <Typography sx={{ color: "white" }} variant="h5">
            {player.userId.username}
          </Typography>
        </>
      )}

      {!player.isPublic && <Chip key={player.userId} label={"Private"} />}

      <Box
        sx={{
          mt: 1.8,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "12px",
          justifyContent: "center",
          height: "72px",
          maxWidth: "100%", // Ensure it doesn't exceed the card's width
         // overflow: "hidden", // Hide any overflowing content
        }}
      >
        {playerPositions && playerPositions.positionsList.length > 0 && playerPositions.positionsList[0].game.name !== undefined &&
          playerPositions.positionsList.map((position) => (
            <Chip
              sx={{
                fontSize: "14px",
                backgroundColor: "#4c13ac",
                color: "white",
                fontWeight: 500,
                maxWidth: "100%", // Prevent chip from overflowing
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for long text
              }}
              label={
                position.positions.length > 0 ? `${position.game.name} - ${position.positions.join(", ")}`: `${position.game.name}`
              }
            ></Chip>
          ))}
      </Box>

      {isTeamManagedByUser && (
        <>
          <Button
            id="player-options-menu"
            aria-controls={isMenuOpen ? "options-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? "true" : undefined}
            onClick={handleClick}
            sx={{
              position: "absolute",
              right: "16px",
              minWidth: "0",
              px: 1,
              top: "16px",
              borderRadius: "100%",
            }}
          >
            <Iconify icon="simple-line-icons:options-vertical" />
          </Button>

          <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={isMenuOpen && isTeamManagedByUser}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "player-options-menu",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleOpenPopup}>
              <Iconify
                icon={"mingcute:user-remove-fill"}
                sx={{ mr: 1 }}
                width={18}
                height={18}
              />
              <Typography variant="body2">Remove Player</Typography>
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={handleOpenSidebar}>
              <Iconify
                icon={"material-symbols:manage-accounts"}
                sx={{ mr: 1 }}
                width={18}
                height={18}
              />
              <Typography variant="body2">Manage Player</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </Card>
  );
};
