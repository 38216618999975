import { Card, Stack, Typography } from "@mui/material";
import React from "react";

const PlayerTeamInfo = ({ teams, players }) => {
  return (
    <Card sx={{ p: 2, display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
      <Stack item sx={6} direction={"column"} alignItems={"center"}>
        <Typography
          variant="body1"
          component="div"
          sx={{ color: "text.disabled", width: "fit-content" }}
        >
          Teams
        </Typography>
        <Typography variant="h3" sx={{ width: "fit-content" }}>
          {teams}
        </Typography>
      </Stack>

      <Stack item sx={6} direction={"column"} alignItems={"center"}>
        <Typography
          variant="body1"
          component="div"
          sx={{ color: "text.disabled" }}
        >
          Players
        </Typography>
        <Typography variant="h3">{players}</Typography>
      </Stack>
    </Card>
  );
};

export default PlayerTeamInfo;
