import axiosInstance from "src/utils/axios";
import axios from "src/utils/axios";

export class PlayerService {
  static getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("accessToken"),
      },
    };
  }
  static async getGames() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/games`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  static async getManagedPlayerById(userId, playerId) {
    const response = await axiosInstance.get(
      `/users/${userId}/players/${playerId}/manage`,
      {
        headers: PlayerService.getHeaders(),
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch player profile");
    }
  }
  static async patchPublicSettingsById(userId, playerId, isPublic) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/players/${playerId}/is-public`,
        { isPublic },
        {
          headers: PlayerService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async invitePlayerToTeam(
    teamId,
    playerId,
    { positionsList, inviteMessage }
  ) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/players/${playerId}/invite`,
        {
          inviteMessage,
          positionsList,
        },
        {
          headers: PlayerService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async inviteSelfToTeam(teamId, playerId, { positionsList }) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/players/${playerId}/invite-self`,
        {
          positionsList,
        },
        {
          headers: PlayerService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async requestToJoinTeam(teamId, { inviteMessage }) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/team-join-requests`,
        {
          inviteMessage,
        },
        {
          headers: PlayerService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async addPlayerToSquad(userId, squadId, { players }) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/squads/${squadId}/add`,
        {
          players,
        },
        {
          headers: PlayerService.getHeaders(),
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }
}
