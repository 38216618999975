/* eslint-disable no-unused-vars */
import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections

import {  useMemo } from 'react';
import OrganizationAccountGeneral from 'src/sections/@dashboard/user/account/OrganizationAccountGeneral';

// ----------------------------------------------------------------------

export default function OrganizationAccount({organization, refetch}) {
    const { themeStretch } = useSettings();
    const { currentTab, onChangeTab } = useTabs('about');
  
    const ACCOUNT_TABS = useMemo(() => {
        const tabs = [
            {
                value: 'about',
                icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
                component:  <OrganizationAccountGeneral organization={organization} refetch={refetch}/> ,
            },

        ]
        return tabs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

 
    return (
        <Page title="Edit Organization Profile">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs sx={{ mt: 4 }}
                    heading="Edit My Organization Profile"
                />
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTab}
                    onChange={onChangeTab}
                    sx={{
                        '& .MuiTab-root': {
                          // minWidth: 'auto', // Ensure auto width for the tabs
                          padding: '12px 12px', // Adjust padding for better clickability
                          justifyContent: 'center', // Center the content
                        },
                      }}
                >
                    {ACCOUNT_TABS.map((tab) => (
                        <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>
                <Box sx={{ mb: 4 }} />
                {ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    );
}
