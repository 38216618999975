import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';
import Markdown from '../../../components/Markdown';
import Scrollbar from '../../../components/Scrollbar';
import MailDetailsToolbar from './MailDetailsToolbar';
import { useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const MarkdownStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------
export const RequestDetails = ({ request, handleAccept, handleReject, handleRevoke, teamId }) => {
  const { user } = useAuth();


  return request.type === "Team Invite" ?
    <InviteDetails user={user} teamId={teamId} invite={request} handleAccept={handleAccept} handleReject={handleReject} handleRevoke={handleRevoke} /> :
    <JoinDetails user={user} teamId={teamId} join={request} handleAccept={handleAccept} handleReject={handleReject} handleRevoke={handleRevoke} />;
}

export default RequestDetails;

export function JoinDetails({ join, handleAccept, handleReject, handleRevoke, user, teamId }) {
  const location = useLocation();
  return (
    <RootStyle>
      <MailDetailsToolbar mail={{
        name: join?.sender?.userId?.username,
        avatar: join?.sender?.avatarUrl,
        createdAt: join?.createdAt,
        status: join.status,
        public: join?.sender?.isPublic,
        playerId: join?.sender?._id,
        teamName: join?.requestedTeam?.name,
        teamPublic: join?.requestedTeam?.isPublic,
        type: "join"
      }}
      />
      <Divider />
      <Box sx={{ p: { xs: 3, md: 5 } }}>
        <Scrollbar sx={{ flexGrow: 1, height: "35vh" }} >
          <Typography variant="h3" gutterBottom>
            {join.title}
          </Typography>
          <MarkdownStyle sx={{}}>
            <Markdown children={join?.inviteMessage} />
          </MarkdownStyle>

        </Scrollbar>
        {join.status === "Pending" && join.type === "Join Request" && teamId && (join.requestedTeam._id === teamId) && location.pathname.startsWith("/dashboard/teams") &&
          (
            <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{ position: "absolute", bottom: 1, right: 1 }}>
              <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleReject(join._id)}>Reject</Button>
              <Button variant="contained" onClick={() => handleAccept(join._id)}>Accept </Button>
            </Stack>
          )
        }

        {join.status === "Pending" && join.type === "Join Request" && (join.sender.userId._id === user._id) && location.pathname.startsWith("/dashboard/player") &&
          (
            <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{ position: "absolute", bottom: 1, right: 1 }}>
              <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleRevoke(join._id)}>Revoke</Button>
            </Stack>
          )
        }
      </Box>
    </RootStyle>
  );
}


export function InviteDetails({ invite, handleAccept, handleReject, handleRevoke, user, teamId }) {
  const location = useLocation();

  return (
    <RootStyle>
      <MailDetailsToolbar mail={{
        name: invite?.invitee?.userId?.username,
        avatar: invite?.invitee?.avatarUrl,
        createdAt: invite?.createdAt,
        status: invite.status,
        teamName: invite?.relatedTeam?.name,
        teamId: invite?.relatedTeam?._id,
        public: invite?.invitee?.isPublic,
        teamPublic: invite?.relatedTeam?.isPublic,
        playerId: invite?.invitee?._id,
        type: "invite"
      }}
      />
      <Divider />
      <Box sx={{ p: { xs: 3, md: 5 } }}>
        <Scrollbar sx={{ flexGrow: 1, height: "35vh" }} >
          <Typography variant="h3" sx={{ mb: 1 }} gutterBottom>
            {invite.title}
          </Typography>
          <GamePositionsList games={invite.positionsList} />
          <MarkdownStyle sx={{ mt: 2 }}>
            <Markdown children={invite?.inviteMessage} />
          </MarkdownStyle>
        </Scrollbar>

      </Box>
      {invite.status === "Pending" && invite.type === "Team Invite" && (invite.invitee.userId._id === user._id) && location.pathname.startsWith("/dashboard/player") &&
        (
          <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{ position: "absolute", bottom: 1, right: 1 }}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleReject(invite._id)}>Reject</Button>
            <Button variant="contained" onClick={() => handleAccept(invite._id)}>Accept </Button>
          </Stack>
        )
      }
      {invite.status === "Pending" && invite.type === "Team Invite" && teamId && (invite.relatedTeam._id === teamId) && location.pathname.startsWith("/dashboard/teams") &&
        (
          <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{ position: "absolute", bottom: 1, right: 1 }}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleRevoke(invite._id)}>Revoke</Button>
          </Stack>
        )
      }
    </RootStyle>
  );
}


export const GamePositionsChip = ({ game }) => {
  const label = useMemo(() => {
    if (!game.positions.length) return game.game.name;
    return `${game.game.name} - ${game.positions.join(", ")}`;
  }, [game])

  return (
    <Chip key={game._id} label={label}
      sx={{
        backgroundColor: "#4c13ac",
        color: "white",
        fontWeight: 500,
        height: "fit-content",
        py: 0.5,
        mr: 0.5,
        mb: 1
      }} />
  )
}

export const GamePositionsList = ({ games }) => {
  return <Box my={1}>
    {games.map(game => <GamePositionsChip game={game} key={game._id} />)}
  </Box>
}
